import { query } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/services/api.service';
import { CommonService } from 'src/services/common.service';

@Component({
    selector: 'app-response',
    templateUrl: './response.component.html',
    styleUrls: ['./response.component.scss']
})
export class ResponseComponent implements OnInit {
    public responseText = 'Your Payment is processing please wait.';
    constructor(private route: ActivatedRoute,
        private apiService: ApiService,
        private commonService: CommonService,
        private router: Router) {
        this.route.queryParams.forEach((param) => {
            if (param && param['token-id']) {
                this.getPaymentResponse(param['token-id']);
            }
        });
    }

    ngOnInit() {
    }

    /**
     * @method getPaymentResponse
     * @param token: string
     * @description this method verifies the payment with the backend
     */
    private getPaymentResponse(token: string) {
        setTimeout(() => {
            // this.commonService.hideSpinner();
        }, 200);
        this.apiService.httpGet(`payments/response?tokenId=${token}`).subscribe((response: any) => {
            if (response && !response.error) {
                this.responseText = 'Payment Success!. Please wait do not refresh or press the back button.';
                this.getPerson();
            } else if (response && response.error) {
                this.responseText = response.data.orderMsg;
                this.router.navigate(['/package/cart'], { queryParams: { failure: true } });
                this.commonService.showErrorToaster(response.data.orderMsg, 'Failure');

            }
        }, (err) => {
            this.router.navigate(['/package/cart'], { queryParams: { failure: true } });
            this.responseText = 'Your card was declined. In order to resolve the issue you will need to contact your bank, or enter different payment card below:';
        });
        this.commonService.hideSpinner();

    }

    /**
     * @method getPerson
     * @description this method fetches the person detail
     */
    private getPerson() {

        this.apiService.httpGet('persons?requestFrom=1').subscribe((response: any) => {
            if (response && response.data) {
                this.commonService.personData = response.data;
                this.commonService.encryptAndSaveData(this.commonService.personData, 'members');
                let personIndex = 0;
                const personReverse = this.commonService.personData.reverse();
                personReverse.forEach((person, index) => {
                    if (!person.dob && !person.deviceId) {
                        personIndex = index;
                    }
                });
                this.router.navigate([`/app/account/welcome/${this.commonService.personData[personIndex].personId}`]);
                this.commonService.encryptAndSaveData(this.commonService.personData[personIndex], 'person');
                this.commonService.personInfo = this.commonService.personData[personIndex];
                localStorage.removeItem('plan-detail');
                localStorage.removeItem('isCreated');
            }
        });
        this.commonService.hideSpinner();

    }
}
