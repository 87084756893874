import { InjectionToken } from '@angular/core';

export const defaultErrors = {
    required: (error) => `Required`,
    minlength: ({ requiredLength, actualLength }) => `Minimum length is ${requiredLength}`,
    maxlength: ({ requiredLength, actualLength }) => `Maximum length is ${requiredLength}`,
    pattern: (error) => 'Please provide a valid format',
    max: ({ max, actual }) => `Maximum value is ${max}`,
    min: ({ min, actual }) => `Minimum value is ${min}`,
    notEqual: (error) => ``,
    notValid: (error) => ''
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
    providedIn: 'root',
    factory: () => defaultErrors
});
