/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/no-unresolved */
import { PackageComponent } from "./admin/package/package.component";
import { ResponseComponent } from "./admin/payments/response/response.component";
import { PricingComponent } from "./admin/package/pricing/pricing.component";
import { CartComponent } from "./admin/package/cart/cart.component";
import { ContactInfoComponent } from "./admin/contact-info/contact-info.component";
import { BillingComponent } from "./admin/billing/billing.component";
import { AddPersonComponent } from "./admin/add-person/add-person.component";
import { LoginComponent } from "./../auth/login/login.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { AppSharedModule } from "src/shared/app-shared/app-shared.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ApiService } from "src/services/api.service";
import { CommonService } from "src/services/common.service";
import { ApiInterceptor } from "src/interceptors/interceptor";
import { ReleaseNotesComponent } from "./admin/release-notes/release-notes.component";
import { AccountsComponent } from "./admin/accounts/accounts.component";
import { LayoutComponent } from "./layout/layout/layout.component";
import { SidenavComponent } from "./layout/sidenav/sidenav.component";
import { AccountsGeneralComponent } from "./admin/accounts/accounts-general/accounts-general.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { FinanceComponent } from "./finance/finance.component";
import { NgbModule, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { CreditCardApprovalComponent } from "./credit-card-approval/credit-card-approval.component";
import { ReportsComponent } from "./reports/reports.component";
import { ControlErrorComponent } from "src/shared/control-error/control-error.component";
import { CalendarModule } from "primeng/calendar";
// eslint-disable-next-line eslint-comments/no-duplicate-disable
// eslint-disable-next-line import/no-unresolved
import { CreateIdComponent } from "src/auth/create-id/create-id.component";
import { AccountNavTabsComponent } from "./admin/accounts/account-nav-tabs/account-nav-tabs.component";
import { AccountHeadingComponent } from "./admin/accounts/account-heading/account-heading.component";
import { AccountCommunicationsComponent } from "./admin/accounts/account-communications/account-communications.component";
import { BugListComponent } from "./bugs/bug-list/bug-list.component";
import { AddBugRecordComponent } from "./bugs/add-bug-record/add-bug-record.component";
import { UpdateBugRecordComponent } from "./bugs/update-bug-record/update-bug-record.component";
import { AlertComponent } from "./ng-material components/alert/alert.component";
import { OrderComponent } from "./order/order.component";
import { CustomSdkComponent } from "./custom-sdk/custom-sdk.component";
import { ApkReleasesComponent } from "./custom-sdk/apk-releases/apk-releases.component";
import { OsVersionsComponent } from "./custom-sdk/os-versions/os-versions.component";
import { AddOsVersionsComponent } from "./ng-material components/add-os-versions/add-os-versions.component";
import { UpdateApkVersionsComponent } from "./ng-material components/update-apk-versions/update-apk-versions.component";
import { CreateAppComponent } from "./ng-material components/create-app/create-app.component";
import { CopyContactsComponent } from "./ng-material components/copy-contacts/copy-contacts.component";
import { LaunchControlModuleModule } from "./launch-control/launch-control-module.module";

@NgModule({
  entryComponents: [
    ControlErrorComponent,
    AlertComponent,
    AddOsVersionsComponent,
    CreateAppComponent,
    UpdateApkVersionsComponent,
    CopyContactsComponent,
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    ReleaseNotesComponent,
    AccountsComponent,
    LayoutComponent,
    SidenavComponent,
    AccountsGeneralComponent,
    DashboardComponent,
    FinanceComponent,
    CreditCardApprovalComponent,
    AddPersonComponent,
    BillingComponent,
    ContactInfoComponent,
    ReportsComponent,
    CartComponent,
    PricingComponent,
    ResponseComponent,
    CreateIdComponent,
    PackageComponent,
    AccountNavTabsComponent,
    AccountHeadingComponent,
    AccountCommunicationsComponent,
    BugListComponent,
    AddBugRecordComponent,
    UpdateBugRecordComponent,
    AlertComponent,
    OrderComponent,
    CustomSdkComponent,
    ApkReleasesComponent,
    OsVersionsComponent,
    AddOsVersionsComponent,
    UpdateApkVersionsComponent,
    CreateAppComponent,
    CopyContactsComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppSharedModule,
    FormsModule,
    CalendarModule,
    NgbModule,
    BrowserAnimationsModule,
    NgbPaginationModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    NgxSpinnerModule,
    LaunchControlModuleModule,
  ],
  providers: [
    ApiService,
    CommonService,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
