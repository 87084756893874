import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ApiService } from "src/services/api.service";
import { CommonService } from "src/services/common.service";
import { ICustomOS, IPersons, ITracks } from "../interfaces";
import { Router } from "@angular/router";
import { AlertComponent } from "src/app/ng-material components/alert/alert.component";
import { CreateTrackComponent } from "../create-track/create-track.component";

@Component({
  selector: "app-tracks",
  templateUrl: "./tracks.component.html",
  styleUrls: ["./tracks.component.scss"],
})
export class TracksComponent implements OnInit {
  columnsToDisplayWithExpand: string[] = ["id", "name", "wiseosversion","action"];
  dataSource: MatTableDataSource<ITracks>;
  selection = new SelectionModel<ITracks>(true, []);
  @ViewChild(MatPaginator, {
    static: false,
  })
  paginator: MatPaginator;
  @ViewChild(MatSort, {
    static: false,
  })
  sort: MatSort;

  expandedElement: any;
  persons: IPersons[] | [];
  customos: ICustomOS[] | [];
  constructor(
    private commonService: CommonService,
    private apiService: ApiService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit() {
    this.getAllTracks();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addNewRow() {
    const dialogref = this.dialog.open(CreateTrackComponent, {});
    dialogref.afterClosed().subscribe((result) => {
      this.getAllTracks();
    });
  }

  navigateToView(row: ITracks) {
    this.commonService.setLocalStorage("track", row.name)
    this.router.navigate(["layout/launch-control/view/" + row.id]);
  }

  deleteRow(row: ITracks) {
    const dialogref = this.dialog.open(AlertComponent, {
      data: {
        title: "Confirm Delete Track",
        message: "Are you sure you want to delete this track?",
        description: `If you proceed, the track will be removed  Only if no persons and build are attached. 
        Please note that this action cannot be undone. Please confirm your decision by clicking the 'OK' button below. 
          `,
      },
    });
    dialogref.afterClosed().subscribe((result) => {
      if (result === true) {
        this.apiService.httpDelete("releasetrack/delete/" + row.id).subscribe((resp: any) => {
          if (resp.statusCode === 200) {
            this.commonService.showSuccessToaster("Successfully Deleted", "Success");
            this.getAllTracks();
          } else {
            this.commonService.showErrorToaster("Cannot be delete", "Opps!");
          }
        });
      }
    });
  }

  editRow(row: ITracks) {
    const dialogref = this.dialog.open(CreateTrackComponent, { data: row });
    dialogref.afterClosed().subscribe((result) => {
      this.getAllTracks();
    });
  }

  getAllTracks() {
    this.apiService.httpGet("releasetrack").subscribe((response: any) => {
      this.commonService.hideSpinner();
      if (response.statusCode === 200) {
        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.commonService.showErrorToaster(response.message, "Opss!");
      }
    });
  }
}
