import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/services/api.service';
import { CommonService } from 'src/services/common.service';
import { User } from 'src/object/user.object';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  public personData: Array<User> = [];
  public previous: any;
  public previousChild: number;
  public env: any;
  @Input() public navId: string;
  @Output() onclose = new EventEmitter();
  isOpen: Array<boolean> = [];
  constructor(private router: Router, public apiService: ApiService, public commonService: CommonService, private route: ActivatedRoute) {

  }

  ngOnInit() {
      // this.env = environment.environmentName;
  }

  /**
   * @method memberDetail
   * @description this method fetches the person detail
   */
  public memberDetail(member: any, route?: string, index?: number, childMenuIndex?: number) {
      if (member) {
          member.linkIndex = index;
          member.childMenuIndex = childMenuIndex;
      }
      this.commonService.encryptAndSaveData(member, 'person');

  }


  /**
   * @method toggleMenu
   * @description this method helps to toggle the menu items in mobile view
   */
  public toggleMenu(goto?: string) {
      this.onclose.emit('close');
      if (goto) {
          this.router.navigate([goto]);
      }
  }


  /**
   * @method logout
   * @description this method logout the user from the app
   */
  public logout() {
      localStorage.clear();
      // this.router.navigate(['/login']);
      window.location.reload();

  }

  /**
   * @method getPerson
   * @description this method fetches the person detail
   */
  public getPerson() {
      this.apiService.httpGet('persons?requestFrom=1').subscribe((response: any) => {
          if (response && response.data) {
              this.commonService.personData = response.data;
              this.commonService.encryptAndSaveData(this.commonService.personData, 'members');
          }
      });
  }

  ngOnDestroy() {
      this.commonService.personInfo = new User();
      this.commonService.personData = [];
      this.commonService.errorMessage = '';

  }
}
