import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CommonService } from "src/services/common.service";

@Component({
  selector: "app-custom-sdk",
  templateUrl: "./custom-sdk.component.html",
  styleUrls: ["./custom-sdk.component.scss"],
})
export class CustomSdkComponent implements OnInit {
  selectedIndex = 0;
  public stageId = 0;
  public apk = "selected pointer";
  public os = "pointer";
  constructor(public commonService: CommonService, private route: ActivatedRoute, public router: Router) {
    const navEndEvent$ = router.events.pipe(filter((e) => e instanceof NavigationEnd));
    navEndEvent$.subscribe((e: NavigationEnd) => {
      console.log("URL ==>", e.url);
      if (e.url === "/layout/sdk/os-versions") {
        this.os = "selected pointer";
        this.apk = "pointer";
      } else {
        this.apk = "selected pointer";
        this.os = "pointer";
      }
    });
    this.stageId = environment.stageId;
    this.onApks();
  }

  ngOnInit() {
    // this.menuFunction();
    this.commonService.checkRoute();
  }

  onActivate(event) {
    window.scroll(0, 0);
  }
  /**
   * onApks
   */
  public onApks() {
    this.apk = "selected pointer";
    this.os = "pointer";
    this.selectedIndex = 0;
    this.router.navigate(["/layout/sdk/apk-releases/"]);
  }
  /**
   * onSelectOS
   */
  public onSelectOS() {
    this.os = "selected pointer";
    this.apk = "pointer";
    this.selectedIndex = 1;
    this.router.navigate(["/layout/sdk/os-versions/"]);
  }
}
