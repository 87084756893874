import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "src/services/api.service";
import { CommonService } from "src/services/common.service";

@Component({
  selector: "app-copy-contacts",
  templateUrl: "./copy-contacts.component.html",
  styleUrls: ["./copy-contacts.component.scss"],
})
export class CopyContactsComponent implements OnInit {
  public form: FormGroup;
  public selectChild = false;
  public childs = [];
  public errorMessage = "";
  public message = "Contact Copied Successfully!";
  public selectedChildId = 0;
  public copySuccess = false;
  constructor(private apiService: ApiService, public commonService: CommonService, private fb: FormBuilder) {
    this.form = this.fb.group({
      email: ["", { validators: [Validators.required] }],
    });

    this.form.get('email').valueChanges.subscribe(val => {
      if (val == "") {
        this.errorMessage = "";
      }

    });
  }

  ngOnInit() { }
  /**
   * @method searchChilds
   * @param parentEmail
   * @description this method will get child
   */
  public searchChilds() {
    this.errorMessage = "";
    if (this.form.valid) {
      this.apiService.httpGet(`admin/persons/${this.form.controls.email.value}`).subscribe((response: any) => {
        // console.log("response ", response);
        this.commonService.hideSpinner();
        if (response.statusCode === 200) {
          this.errorMessage = "";
          if (response.data && response.data.length > 0) {
            this.childs = response.data;
            const index = this.childs.findIndex((a) => a.id === this.selectedChildId);
            console.log("index ===>", index);
            if (index != -1) {
              this.childs[index].isSelected = true;
            }
          }
        } else {
          this.errorMessage = "user not found with entered email!";
        }
      });
    }
  }
  /**
   * clearMessage
   */
  public clearMessage() {
    console.log("onhcnaged Called");

    if (this.form.controls.email.value == "") {
      this.errorMessage = ""
    }
  }
  public copyFrom(id: number) {
    this.selectChild = true;
    this.selectedChildId = id;
    const index = this.childs.findIndex((a) => a.id === id);
    // console.log("index ===>", index);
    if (index != -1) {
      this.childs[index].isSelected = true;
    }
  }
  public copyTo(id: number) {

    // console.log("selected Child Id ====>", this.selectedChildId);
    // console.log("copy To id ====>", id);
    const body = {
      copy_from_person_id: this.selectedChildId,
      copy_to_person_id: id,
    };
    this.apiService.httpPost("admin/copycontacts", body).subscribe((response: any) => {
      // console.log("copycontacts response  ====>", response);
      this.commonService.hideSpinner();
      this.copySuccess = true;
      if (response.error === 0) {
        this.message = "Contact Copied Successfully!"
      } else {
        this.message = response.message ? response.message : "Something went wrong Please try again!"
      }

    });
  }
}
