import { Component, OnInit, OnDestroy, Renderer, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/services/api.service';
import { CommonService } from 'src/services/common.service';
import { Pattern } from 'src/constants/pattern';
import { BillinInfo } from 'src/object/billing-info.object';
import { environment } from 'src/environments/environment';
declare var moment: any

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.css']
})

export class PricingComponent implements OnInit, OnDestroy {
    public features = [];
    public planFeatures = [];
    public package = 0;
    public devices = [1];
    public planDetail: any;
    public showPlans = true;
    public numberOfDevice = 'One';
    public notEqual = false;
    public form: FormGroup;
    public plans = [];
    public isMobile = false;
    public planId = 0;
    private billingInfo = new BillinInfo();
    public showCalendar = false;
    public selectedDate = '';
    public today = '';
    public minDate = new Date();
    public maxDate = ''
    public day = moment(new Date()).format('D');
    public showLogin = false;
    public devicesList = [];
    public userId= 0;
    public isComp = 0;
    public showResponseMsg  = '';
    public deviceDetails: any;
    public devicesCount = 1;
    public duration = 0;
    public durationOptions = [{
        'id': 1,
        "value": 30
    },
    {
        "id": 2,
        "value": 365
    }];
    constructor(private apiService: ApiService, public commonService: CommonService, private fb: FormBuilder, private router: Router, public activatedRoute: ActivatedRoute, private renderer: Renderer, private elem: ElementRef) {
        this.commonService.errorMessage = '';
        this.isComp = parseInt(localStorage.getItem('comp'));
        if (this.day == '1' || this.day == '21' || this.day == '31') {
            this.today = moment(new Date()).format('MMM D') + ' st';

        } else if (this.day == '2' || this.day == '22') {
            this.today = moment(new Date()).format('MMM D') + ' nd';

        } else if (this.day == '3' || this.day == '23') {
            this.today = moment(new Date()).format('MMM D') + ' rd';

        } else {
            this.today = moment(new Date()).format('MMM D') + ' th';

        }

        this.activatedRoute.params.forEach((param) => {
            if (param && param.id && param.userId) {
                this.planId = parseInt(param.id);
                this.userId = param.userId;
                if(localStorage.getItem('plan-detail')) {
                    this.planDetail = this.commonService.decryptData('plan-detail');


                this.numberOfDevice = this.planDetail.devices;
                for (let i = 1; i < this.planDetail.devices; i++) {
                    this.devices.push(i);
                }
            }
            }
        })
    }

    ngOnInit() {
        // $('#datetimepicker').datetimepicker({
        //     timepicker: false,
        //     formatDate: 'Y/m/d',
        //     minDate: '-1970/01/02',//yesterday is minimum date(for today use 0 or -1970/01/01)
        //     maxDate: '+1970/01/02'//tomorrow is maximum date calendar
        // });
        this.showResponseMsg = ''
        this.getPlans();
        this.form = this.fb.group({
            firstname: ['', { validators: [Validators.required, Validators.minLength(3)] }],
            lastname: ['', { validators: [Validators.required] }],
            username: ['', { validators: [Validators.required, Validators.pattern(Pattern.EMAIL_REGEX)] }],
            password: ['', { validators: [Validators.required] }],
            confirmPassword: [''],
            usertype: ['']
        });

        this.form.valueChanges.subscribe(() => {
            this.checkValidatity();
        })
    }

    ngOnDestroy() {
        // if (this.isMobile) {
        //     document.body.style.width = '100% !important'
        // } else {
        //     document.getElementById('content').style.width = '600px';

        // }
        this.showResponseMsg = ''
        this.planDetail = '';
        this.commonService.errorMessage = '';
        this.showLogin = false
    }

    /**
     * @method getPricing
     * @description this method gets the pricing info
     */



    /**
     * @method flipCard
     * @param revert: ?number
     * @description this method makes the card to flip
     */
    public flipCard(revert?: number) {
        if (!revert) {
            document.getElementById('styleCard').className = 'p_card';
            setTimeout(() => {
                document.getElementById('card-front').style.display = 'block';
            }, 200);
        } else {
            this.planDetail = '';

            document.getElementById('styleCard').className += ' flipped';
            document.getElementById('card-front').style.display = 'none';
        }

    }

    /**
     * @method getPlans
     * @description this method gets the plan list
     */
    private getPlans() {
        this.apiService.httpGet('plans').subscribe((response: any) => {
            if (response && response.plans) {
                this.plans = response.plans;
                this.plans.sort((a, b) => {
                    return a.price - b.price;
                });
                //console.log(this.plans);
                this.plans.forEach((plan, index) => {
                    var deviceInfo = {
                      "planId": plan.id,
                      "name": plan.name,
                      "title": plan.title,
                      "subname": plan.subname,
                      "offerText": plan.offerText,
                      "capacities": [plan.capacity],
                      "prices": plan.price,
                      "durations": plan.duration,
                      "colors": plan.color,
                      "sizeClass": "small",
                      "imgSrc": plan.image
                    };
                    this.devicesList.push(deviceInfo);
                });
                console.log(this.devicesList);
                document.querySelector(".container-fluid").classList.add("show");
                this.planDetail = this.devicesList[0];
                /*this.plans.forEach((plan) => {
                    if (this.planId === plan.id) {

                        this.planDetail = plan;

                    }
                })*/

            }
        });
    }

    /**
     * @method addDevice
     * @description this method pushes devices in array
     */
    public addDevice() {
        const device = this.devices.length + 1;
        this.devices.push(device);
        this.numberOfDevice = this.commonService.convertNumberToString(this.devices.length);
    }

    /**
     * @method removeDevice
     * @description this method pops devices from array
     */
    public removeDevice() {
        this.devices.pop();
        this.numberOfDevice = this.commonService.convertNumberToString(this.devices.length);
    }

    public changeDuration(value) {
      console.log(this.planDetail);
      console.log(this.planDetail.duration);
      var durationIndex = this.planDetail.durations.findIndex((a) => a == value);
      this.duration = value;
      this.planDetail.duration = value;
      this.planDetail.price = this.planDetail.prices[durationIndex];
    }


    /**
     * @method changeThePage
     * @param showPlan: boolean
     */
    public changeThePage(showPlan: boolean) {
        if (showPlan) {
            this.elem.nativeElement.querySelector('.closeButton').click();
            this.showPlans = false;
            this.checkTokenInfo();

        }
    }

    /**
     * @method submit
     * @description this method posts the form
     */
    public submit() {
        this.checkValidatity('s');
        setTimeout(() => {
          var usertype = 1;
          if(environment.stageId == 2) {
            usertype = 1;
          }
          else if(environment.stageId == 3) {
            usertype = 2;
          }
          this.form.patchValue({
            usertype: usertype
          });
            if (this.form.valid && !this.notEqual) {
                this.planDetail.devicesCount = this.devicesCount;
                this.planDetail.duration = Number(this.duration);
                this.planDetail.devices = this.devicesCount;
                this.planDetail.tax = 4.75;
                if (!this.selectedDate) {
                    this.selectedDate = moment(new Date()).format('YYYY-MM-DD')
                }
                this.planDetail.subscriptionDate = moment(this.selectedDate).format('YYYY-MM-DD');
                this.commonService.encryptAndSaveData({ ...this.form.value }, 'account-id');
                this.commonService.encryptAndSaveData(this.planDetail, 'plan-detail');
                this.apiService.httpPost('users/register', this.form.value).subscribe((response: any) => {
                    if (response) {
                        if (response.error === 5) {
                            this.showLogin = true;
                            this.commonService.hideSpinner();
                        } else {
                            const accessToken = localStorage.getItem('access-token');
                            if (!accessToken) {
                                this.commonService.setLoginData(response.accessToken);
                                localStorage.setItem('isCreated', 'true');
                            }

                                this.router.navigate(['layout/package/cart/',this.userId]);

                        }

                    }
                });
            }
        }, 500);

    }


    public  createPerson() {
        if (!this.selectedDate) {
            this.selectedDate = moment(new Date()).format('YYYY-MM-DD')
        }
        this.planDetail.subscriptionDate = moment(this.selectedDate).format('YYYY-MM-DD');
        const plan = {
            planId: this.planDetail.id,
            unitAmount: this.planDetail.price,
            quantity: this.devices.length,
            total: 0,
            taxAmount: 0,
            amount: 0,
            plan: this.planDetail.name,
            subscriptionDate: this.planDetail.subscriptionDate
        };
        this.apiService.httpPost(`admin/accounts/${this.userId}/addperson`, plan).subscribe((response: any) => {
            if (response ) {
                this.showResponseMsg = response.message;
                setTimeout(()=>{
                    this.showResponseMsg = ''
                    this.router.navigate(['/layout/accounts',this.userId,'detail'])
                },1000);
            }
        });
    }

    /**
     * @method gotoLogin
     * @description this method goes to login page
     */
    public gotoLogin() {
        let email = this.commonService.encrypt(this.form.value.username);
        this.router.navigate([`login`], { queryParams: { id: email } })

    }

    /**
     * @method checkTokenInfo
     * @description this method checks for the access token
     */
    private checkTokenInfo() {
        const token = localStorage.getItem('access-token');
        if (token) {
            this.planDetail.devicesCount = this.devicesCount;
            this.planDetail.duration = Number(this.duration);
            this.planDetail.devices = this.devicesCount;
            this.planDetail.tax = 4.75;
            if (!this.selectedDate) {
                this.selectedDate = moment(new Date()).format('YYYY-MM-DD')
            }
            this.planDetail.subscriptionDate = moment(this.selectedDate).format('YYYY-MM-DD');
            console.log(this.selectedDate)
            this.commonService.encryptAndSaveData(this.planDetail, 'plan-detail');
            this.router.navigate(['layout/package/cart',this.userId]);
        }
    }

    /**
     * @method checkValidatity
     * @description this method checks for password validity
     */
    public checkValidatity(submit?) {
        if (submit) {
            this.form.controls.confirmPassword.markAsDirty();
        }
        if (this.form.value.password !== this.form.value.confirmPassword) {
            this.notEqual = true;
        } else {
            this.notEqual = false;
        }
    }

    public showDate() {
        this.day = moment(this.selectedDate).format('D')
        if (this.day == '1' || this.day == '21' || this.day == '31') {
            this.day = moment(this.selectedDate).format('MMM D') + ' st';

        } else if (this.day == '2' || this.day == '22') {
            this.day = moment(this.selectedDate).format('MMM D') + ' nd';

        } else if (this.day == '3' || this.day == '23') {
            this.day = moment(this.selectedDate).format('MMM D') + ' rd';

        } else {
            this.day = moment(this.selectedDate).format('MMM D') + ' th';

        }
    }

    public flip(targetID) {
        //console.log(targetID);
        var cards = this.elem.nativeElement.querySelectorAll(".device-card");
        var images = this.elem.nativeElement.querySelectorAll(".img-column img");
        cards[targetID].classList.toggle("flipped");
        images[targetID].classList.toggle("flipped");
        //deviceGrid.classList.toggle("off");
        this.elem.nativeElement.querySelector('.main-content').classList.toggle("off");
        setTimeout(function () { document.querySelector("#deviceModal1 .card-img").classList.toggle("off"); }, 500);
        console.log("Card " + targetID + " flipped");
    }

    public flipReset(targetID) {
        //$('#deviceModal1').modal('hide');
        this.elem.nativeElement.querySelector('.closeButton').click();
        var cards = document.querySelectorAll(".device-card");
        var images = document.querySelectorAll(".img-column img");
        cards[targetID].classList.remove("flipped");
        images[targetID].classList.remove("flipped");
        this.elem.nativeElement.querySelector('.main-content').classList.remove("off");
        this.elem.nativeElement.querySelector("#deviceModal1 .card-img").classList.add("off");
        //setTimeout(function () { document.querySelector("#deviceModal1 .card-img").classList.add("off"); }, 500);
        //console.log("Card " + targetID + " reset function");
    }

    //load modal CODE - for when a card is selected
    public loadModal(i) {
        //console.log(i);
        document.body.setAttribute("style", "overflow-y: auto; perspective: 2000px;"); //NB: I wanted this code to run on page load, but I couldn't find a way sinc this is typescript. Any advice on how would be greatly appreciated.
        this.flip(i);
        //name and images
        this.deviceDetails = this.devicesList[i];
        this.deviceDetails.index = i;
        this.planDetail = this.deviceDetails;
        this.planDetail.price = this.deviceDetails.prices.length > 1 ? Number(this.deviceDetails.prices[1]) : Number(this.deviceDetails.prices[0]);
        this.planDetail.duration = this.deviceDetails.durations.length > 1 ? Number(this.deviceDetails.durations[1]) : Number(this.deviceDetails.durations[0]);
        this.duration = this.deviceDetails.durations.length > 1 ? this.deviceDetails.durations[1] : this.deviceDetails.durations[0];
        this.planDetail.id = this.deviceDetails.planId;
        //console.log(this.planDetail);
        //console.log(this.deviceDetails);
        //this.elem.nativeElement.querySelector("#deviceModal1 .modal-title h3").innerText = this.devicesList[i].brand + " " + this.devicesList[i].name;
        //this.elem.nativeElement.querySelector("#deviceModal1 #deviceName").value = this.devicesList[i].name;
        //this.elem.nativeElement.querySelector("#deviceModal1 .card-img").src = this.devicesList[i].imgSrc;

        //build color options
        /*var colorOptions = this.elem.nativeElement.querySelector("#deviceModal1 .color-options");
        colorOptions.innerHTML = "";
        for (var j = 0; j < this.devicesList[i].colors.length; j++) {
            var currentColor = this.devicesList[i].colors[j];
            var divElem = document.createElement("div");
            divElem.classList.add("col");
            var inputElem = document.createElement("input");
            inputElem.classList.add("form-check-input");
            inputElem.setAttribute("type", "radio");
            inputElem.setAttribute("name", "colors");
            inputElem.setAttribute("id", "colors" + currentColor.charAt(0).toUpperCase() + currentColor.slice(1));
            inputElem.value = currentColor;
            divElem.appendChild(inputElem);
            var labelElem = document.createElement("label");
            labelElem.classList.add("form-check-label");
            labelElem.setAttribute("for", "colors" + currentColor.charAt(0).toUpperCase() + currentColor.slice(1));
            labelElem.innerText = currentColor;
            divElem.appendChild(labelElem);
            colorOptions.appendChild(divElem);
        }
        //build capacity Options
        var capacityOptions = document.querySelector("#deviceModal1 .capacity-options");
        capacityOptions.innerHTML = "";
        for (var j = 0; j < this.devicesList[i].capacities.length; j++) {
            var currentCapacity = this.devicesList[i].capacities[j];
            var currentPrice = this.devicesList[i].prices[j];
            var divElem = document.createElement("div");
            divElem.classList.add("col");
            var inputElem = document.createElement("input");
            inputElem.classList.add("form-check-input");
            inputElem.setAttribute("type", "radio");
            inputElem.setAttribute("name", "capacity");
            inputElem.setAttribute("id", "gb" + currentCapacity);
            inputElem.value = currentCapacity + " GB";
            if (j == 0) {
                inputElem.checked = true;
            }
            divElem.appendChild(inputElem);
            var labelElem = document.createElement("label");
            labelElem.classList.add("form-check-label");
            labelElem.setAttribute("for", "gb" + currentCapacity);
            labelElem.setAttribute("onclick", "updatePaymentText('" + currentPrice + "');");
            var capacityLabel = document.createElement("div");
            capacityLabel.classList.add("capacity-size");
            capacityLabel.innerText = currentCapacity;
            labelElem.appendChild(capacityLabel);
            var priceLabel = document.createElement("div");
            priceLabel.classList.add("capacity-price");
            priceLabel.innerText = "$" + currentPrice;
            labelElem.appendChild(priceLabel);
            divElem.appendChild(labelElem);
            capacityOptions.appendChild(divElem);
        }
        //update payment Options
        var plans = this.elem.nativeElement.querySelectorAll("#deviceModal1 .plan-options .plan-price");
        var installmentPrice = plans[0];
        var upFrontPrice = plans[1];
        installmentPrice.innerText = "$" + Math.ceil(this.devicesList[i].prices[0] / 12) + " for 12 months";
        upFrontPrice.innerText = "Pay once $" + this.devicesList[i].prices[0];
        //update GTM tag
        var continueButton = document.querySelector("#deviceModal1 .modal-footer input[type='submit']");
        continueButton.setAttribute("id", "submit" + this.devicesList[i].submitGTMTag);
        //update flip script for close button and modal backdrop
        document.querySelector("#deviceModal1 button.close").setAttribute("click", "flipReset('" + i + "');");*/
        //setTimeout(function () { document.querySelector(".modal-backdrop").setAttribute("onclick", "flipReset('" + i + "');"); }, 500);
    }
}
