import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators , FormControl} from '@angular/forms';
import { Pattern } from 'src/constants/pattern';
import { CommonService } from 'src/services/common.service';
import { encode, decode } from 'js-base64';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  public errorMessage = null;
  public successMessage = null;
  public form: FormGroup;
  public userEmail = null;
  public firstName = null;
  public lastName = null;
  public notEqual = false;
  public showLogin = false; 

  constructor(
    private router: Router,
    private apiService: ApiService,
    private fb: FormBuilder,
    public commonService: CommonService,
    private route: ActivatedRoute

  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', { validators: [Validators.required] }],
      firstname: ['', { validators: Validators.required }],
      lastname: ['', { validators: Validators.required }],
      orderItems: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
  });
  }


  onEmailSubmit() {
    if (this.form.valid) {
        let body = this.form.value;
        body.orderItems = parseInt(this.form.value.orderItems);
        console.log('Response ==>', body );

        if (isNaN(body.orderItems) === true ) {
          this.errorMessage = 'Order Items Should be Number'
          this.commonService.hideSpinner();
          return;
        }

        this.apiService.httpPost('order/checkout', body).subscribe((response: any) => {
            console.log('RESPONSE ===>', response);
            if (response.error) {
                this.errorMessage = response.message;
                this.commonService.hideSpinner();
            }
            if (response && !response.error) {
                this.successMessage = 'Order Created Successfully'
                this.commonService.hideSpinner();
            }
          }, err => {
            this.commonService.hideSpinner();
        });
    }
  }
}
