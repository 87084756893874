import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { CommonService } from "src/services/common.service";

@Component({
  selector: "app-launch-control",
  templateUrl: "./launch-control.component.html",
  styleUrls: ["./launch-control.component.scss"],
})
export class LaunchControlComponent implements OnInit {
  public track = "Launch Control";
  constructor(private commonService: CommonService, private router: Router) {
    this.commonService.watchStorage().subscribe((data: { name: string; value: string }) => {
      if (data.name === "track") {
        this.track = localStorage.getItem("track");
      }
    });
    const navEndEvent$ = router.events.pipe(filter((e) => e instanceof NavigationEnd));
    navEndEvent$.subscribe((e: NavigationEnd) => {
      console.log(e.url);
      if (e.url == "/layout/launch-control" || e.url == "/layout/launch-control/tracks") {
        this.track = "Launch Control";
      }
    });
  }

  ngOnInit() {
  }
  onActivate(event) {
    window.scroll(0, 0);
  }
}
