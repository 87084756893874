import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { IApp } from "../interfaces";
import { ApiService } from "src/services/api.service";
import { CommonService } from "src/services/common.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { AlertComponent } from "src/app/ng-material components/alert/alert.component";
import { NgDynamicBreadcrumbService } from "ng-dynamic-breadcrumb";

export interface PeriodicElement {
  id: number;
  app_name: string;
  version: number;
  app_id: number;
  version_in_track: number;
  updated_at: string;
}

export interface GroupBy {
  app_name: string;
  isGroupBy: boolean;
}

@Component({
  selector: "app-attach-apps",
  templateUrl: "./attach-apps.component.html",
  styleUrls: ["./attach-apps.component.scss"],
})
export class AttachAppsComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ["no", "name", "version", "date", "action"];
  trackId: number;
  dataSource: MatTableDataSource<PeriodicElement | GroupBy>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  constructor(
    private apiService: ApiService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService
  ) {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params) {
        this.trackId = parseInt(params.id);
        this.getApps(this.trackId);
      }
    });

  }
  ngAfterViewInit(): void {
    this.ngDynamicBreadcrumbService.updateBreadcrumb([
      { label: "Launch control", url: "/layout/launch-control" },
      { label: "Apps", url: `/layout/launch-control/view/${this.trackId}/apps` },
      { label: "Attach apps", url: `/layout/launch-control/apps/${this.trackId}` },
    ]);  }
  ngOnInit(): void {
    this.dataSource = new MatTableDataSource([]);
  }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  attachApp(row) {
    console.log("ROW ::  ", row);
    const dialogref = this.dialog.open(AlertComponent, {
      data: {
        title: "Attach App to track",
        message: "Are you sure you want to attach this App Version to the track?",
        description: "This action will make the selected App Version to the track for future releases. Please confirm your decision by clicking the 'OK' button below.",
      },
    });
    dialogref.afterClosed().subscribe((result) => {
      if (result === true) {
        const data = {
          track_id: this.trackId,
          apps_version_id: row.id,
          app_id: row.app_id,
        };
        this.apiService.httpPost("releasetrack/apps", data).subscribe((response: any) => {
          this.commonService.hideSpinner();
          if (response.statusCode === 200) {
            this.commonService.showSuccessToaster("Successfully Attached!", "Success");
            this.getApps(this.trackId);
          } else {
            this.commonService.showErrorToaster(response.message, "Opss!");
          }
        });
      }
    });
  }

  getApps(id: number) {
    this.apiService.httpGet("releasetrack/appversions/" + id).subscribe((resp: any) => {
      if (resp.statusCode === 200) {
        const result: (PeriodicElement | GroupBy)[] = [];
        const keys = Object.keys(resp.data);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          result.push({ app_name: key, isGroupBy: true });
          const array = resp.data[key];
          if (array) {
            array.map((res: IApp) => {
              result.push({
                id: res.id,
                app_name: res.app_name,
                version: res.version,
                app_id: res.app_id,
                updated_at: res.time_modified,
                version_in_track: res.version_in_track,
              });
            });
          }
        }
        this.dataSource = new MatTableDataSource(result);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }
 }