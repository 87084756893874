import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ApiService } from "src/services/api.service";
import { CommonService } from "src/services/common.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ICustomOS } from "../interfaces";
import { MatDialog } from "@angular/material";
import { AlertComponent } from "src/app/ng-material components/alert/alert.component";
import { NgDynamicBreadcrumbService } from "ng-dynamic-breadcrumb";

@Component({
  selector: "app-attach-os",
  templateUrl: "./attach-os.component.html",
  styleUrls: ["./attach-os.component.scss"],
})
export class AttachOsComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    "id",
    "datetime",
    "rom_type",
    "size",
    "wise_os_version",
    "device_type",
    "created_at",
    "action",
  ];
  dataSource: MatTableDataSource<ICustomOS>;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  private trackId;
  constructor(
    private commonService: CommonService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService

  ) {

    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params) {
        this.trackId = params.id;
      }
    });

    this.getAllOS();
  }

  ngAfterViewInit() {
    this.ngDynamicBreadcrumbService.updateBreadcrumb([
      { label: "Launch control", url: "/layout/launch-control" },
      { label: "OS build", url: `/layout/launch-control/view/${this.trackId}/os` },
      { label: "Attach OS build", url: `/layout/launch-control/os/${this.trackId}` },
    ]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllOS() {
    this.apiService.httpGet("releasetrack/customos/" + this.trackId).subscribe((response: any) => {
      this.commonService.hideSpinner();
      if (response.statusCode === 200) {
        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.commonService.showErrorToaster(response.message, "Opss!");
      }
    });
  }
  attachOs(row: ICustomOS) {
    const dialogref = this.dialog.open(AlertComponent, {
      data: {
        title: "Attach OS to track",
        message: "Are you sure you want to attach this operating system to the track?",
        description:
          "This action will make the selected OS available to the track for future releases.Please confirm your decision by clicking the 'OK' button below.",
      },
    });
    dialogref.afterClosed().subscribe((result) => {
      if (result === true) {
        const data = {
          t_id: parseInt(this.trackId),
          os_id: row.id,
        };
        this.apiService.httpPost("releasetrack/os/attach", data).subscribe((response: any) => {
          this.commonService.hideSpinner();
          if (response.statusCode === 200) {
            this.commonService.showSuccessToaster("Successfully Attached!", "Success");
            this.router.navigate(["layout/launch-control/view/" + this.trackId]);
          } else {
            this.commonService.showErrorToaster(response.message, "Opss!");
          }
        });
      }
    });
  }
}
