import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material";
import { Router } from "@angular/router";
import { Pattern } from "src/constants/pattern";
import { environment } from "src/environments/environment";
import { ApiService } from "src/services/api.service";
import { CommonService } from "src/services/common.service";
import { S3 } from "aws-sdk";
import { AlertComponent } from "../alert/alert.component";
const bucket = new S3({
  accessKeyId: "AKIA6KEDRNVDPVFTHCXS",
  secretAccessKey: "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",
  region: "us-east-1",
});
@Component({
  selector: "app-create-app",
  templateUrl: "./create-app.component.html",
  styleUrls: ["./create-app.component.scss"],
})
export class CreateAppComponent implements OnInit {
  public Notesform: FormArray;
  public form: FormGroup;
  public stageId = 0;
  public apkBucket = "";
  private appId = 0;
  private appName = "";
  public noteTypes = [];
  public uploadedFiles;
  public progressValue = 0;
  public errorMessage = "";
  private previousValue = 0;

  constructor(
    public dialogref: MatDialogRef<CreateAppComponent>,
    private apiService: ApiService,
    public commonService: CommonService,
    private dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder
  ) {
    // document.getElementById('content').style.width = '800px'
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: ["", { validators: [Validators.required] }],
      package: ["", { validators: [Validators.required] }],
      apkname: ["", { validators: [Validators.required] }],
      version: [
        "",
        {
          validators: [Validators.required, Validators.pattern(Pattern.NUMERIC)],
        },
      ],
      versionStageID: "",
      notes: this.fb.array([this.createNotes()]),
      apk: ["", { validators: [Validators.required] }],
      checksum: ["", { validators: [Validators.required] }],
    });
    this.getNoteTypes();
    this.stageId = environment.stageId;
    if (this.stageId == 1) {
      this.apkBucket = "techlesscustomsdkdevapks";
    } else if (this.stageId == 2) {
      this.apkBucket = "techlesscustomsdkstageapks";
    } else if (this.stageId == 3) {
      this.apkBucket = "techlesscustomsdkprodapks";
    } else if (this.stageId == 4) {
      this.apkBucket = "techlesscustomsdkpreprodapks";
    }
  }

  /**
   * @method getNoteTypes
   * @description this method will get Note types for update Note popup
   */
  private getNoteTypes() {
    this.apiService.httpGet("apps/noteTypes").subscribe((response: any) => {
      this.noteTypes = response.data;
    });
    this.commonService.hideSpinner();
  }

  /**
   * @method createNotes
   * @description this method gets the form array controls
   */
  public createNotes(): FormGroup {
    return this.fb.group({
      type: ["", { validators: [Validators.required] }],
      note: ["", { validators: [Validators.required] }],
    });
  }
  /**
   * @method removeNotes
   * @description this method removes the data
   */
  public removeNotes(index: number) {
    const notes = this.form.get("notes") as FormArray;
    notes.removeAt(index);
  }

  /**
   * @method addNotesForm
   * @description this will add note form
   */
  public addNotesForm(): void {
    this.Notesform = this.form.get("notes") as FormArray;
    this.Notesform.push(this.createNotes());
  }

  /**
   * @method clearForm
   * @description this method will clearForm
   */
  public clearForm() {
    this.form.reset();
  }
  public fileChange(element: any) {
    console.log("element.target.files ===>", element.target.files);

    this.uploadedFiles = element.target.files;
    const ext = /^.+\.([^.]+)$/.exec(element.target.files.item(0).name);
    const extension = ext == null ? "" : ext[1];

    if (extension === "apk") {
      this.errorMessage = "";
      this.uploadedFiles = element.target.files;
    } else {
      this.errorMessage = "Please select only .apk file";
      this.form.patchValue({
        apk: "",
      });
    }
  }

  /**
   * @method onConfirm
   * @description This method runs to open confirmation dialogue box when updating app if all fields are filled
   */
  public onConfirm() {
    if (this.form.valid) {
      const dialogref = this.dialog.open(AlertComponent, {
        data: {
          title: "Create App?",
          message: "Do you want to Create this app?",
          description: "Creating app will update this app on all devices.",
        },
      });
      dialogref.afterClosed().subscribe((result) => {
        if (result === true) {
          this.saveNotes();
        }
      });
    } else {
      console.log("form is not valid", this.form.value);
    }
  }
  /**
   * @method saveNotes
   * @description this method saves the notes to the form group
   */
  public saveNotes() {
    this.form.patchValue({
      versionStageID: this.stageId,
    });
    if (this.uploadedFiles != undefined && this.uploadedFiles.length > 0) {
      this.commonService.showSpinner();
      const file = this.uploadedFiles.item(0);
      const contentType = file.type;
      const bucket = new S3({
        accessKeyId: "AKIA6KEDRNVDPVFTHCXS",
        secretAccessKey: "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",
        region: "us-east-1",
      });
      const key = this.form.value.name.toLowerCase() + '/' + this.form.value.name.toLowerCase() + "_" + this.form.value.version + ".apk";
      const params = {
        Bucket: this.apkBucket,
        Key: key,
        Body: file,
        ContentType: contentType,
      };
      bucket
        .upload(params, (err, data) => {
          if (err) {
            this.commonService.showErrorToaster("Request Time out", "Error");
            this.dialogref.close();

            console.log("Data :", data);
            console.log("Error :", JSON.stringify(err));
          } else {
            this.apiService.httpPost("customos/apps", this.form.value).subscribe((resp: any) => {
              this.commonService.showSuccessToaster(resp.message, "Notes Added");
              this.clearForm();
              this.dialog.closeAll();
            });
          }
          console.log("File Uploaded.", data);
        })
        .on("httpUploadProgress", (progress) => {
          this.progressValue = Math.round((progress.loaded / progress.total) * 100);
          if (this.progressValue === 100) {
          }
          if (this.previousValue > this.progressValue) {
            this.commonService.showErrorToaster("Poor internet Connection", "Reconnecting");
          }
          this.previousValue = this.progressValue;
        });
      this.commonService.hideSpinner();
    }
  }
  /**
   * @method formControl
   * @description this get form controls.
   */
  get formControl() {
    return this.form.controls;
  }
}
