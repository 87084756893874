export class User {
  dateCreated = "";
  dateModified = "";
  deviceId = 0;
  firstname = "";
  lastname = "";
  middlename = "";
  personId = 0;
  relation = "";
  status = 0;
  email = "";
  dob = "";
  roleTitle = "";
  isParent = false;
  username = "";
}

export interface IUsesDashboardData {
  activeUsers: number;
  totalUsers: number;
  familyAccounts: number;
  personAccounts: number;
}
