import { CommonService } from './common.service';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    public personData: EventEmitter<any> = new EventEmitter();
    constructor(private http: HttpClient, public commonService: CommonService) {
    }


    /**
   * @method httpGetWithUrl
   * @param url
   * @description this method uses http get
   */
    public httpGetWithUrl(url: string) {
        return this.http.get(url);
    }

    /**
     * @method httpPostWithUrl
     * @param url
     * @description this method uses http get
     */
    public httpPostWithUrl(url: string, data: any) {
        return this.http.post(url, data);
    }


    /**
     * @method httpPutWithUrl
     * @param url
     * @description this method uses http get
     */
    public httpPutWithUrl(url: string, data: any) {
        return this.http.put(url, data);
    }

    /**
     * @method httpGet
     * @param url
     * @description this method uses http get
     */
    public httpGet(url: string) {
        return this.http.get(environment.apiUrl + url);
    }

    /**
     * @method httpPost
     * @param url
     * @param body
     * @description this method uses http post
     */
    public httpPost(url: string, body: any) {
        return this.http.post(environment.apiUrl + url, body);
    }

    /**
     * @method httpPut
     * @param url
     * @param body
     * @description this method uses http put
     */
    public httpPut(url: string, body: any) {
        return this.http.put(environment.apiUrl + url, body);
    }

    /**
     * @method httpDelete
     * @param url
     * @description this method uses http delete
     */
    public httpDelete(url: string) {
        return this.http.delete(environment.apiUrl + url);
    }



}
