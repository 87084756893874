import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/services/api.service";
import { CommonService } from "src/services/common.service";

@Component({
  selector: "app-bug-list",
  templateUrl: "./bug-list.component.html",
  styleUrls: ["./bug-list.component.scss"],
})
export class BugListComponent implements OnInit {
  public bugs = [];
  constructor(private apiService: ApiService, private router: Router, private commonService: CommonService) {}

  ngOnInit() {
    this.getBugList();
  }
  public getBugList(): any {
    this.apiService.httpGet("bugs/getAllBugs").subscribe((response: any) => {
      this.commonService.hideSpinner();
      this.bugs = response.data;
    });
  }
  public getFilteredValue(value) {
    return this.bugs.filter((a) => a.id == value);
  }
  onDelete(val) {
    this.apiService.httpDelete(`bugs/deletebug/${val}`).subscribe((respponse: any) => {
      if (respponse.message === "success") {
        this.getBugList();
      }
    });
  }
  onEdite(parm: any) {
    this.router.navigate(["/layout/bug-list/update"], { queryParams: { id: parm } });
  }
}
