import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Member } from "src/object/member.object";
import { ApiService } from "src/services/api.service";
import { CommonService } from "src/services/common.service";
import { DateFields } from "src/constants/pattern";
import { DbBackup } from "../../../object/dbback.model";
import { saveAs } from "file-saver";

declare var moment: any;

@Component({
  selector: "app-add-person",
  templateUrl: "./add-person.component.html",
  styleUrls: ["./add-person.component.scss"],
})
export class AddPersonComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public password = "password";
  public member: Member = new Member();
  public invalidDate = false;
  public qrCodeUrl = "";
  public showQr = 0;
  public roles = [];
  public submitMsg = "";
  public dobDays = [];
  public dobMonths = [];
  public userId = "";
  public showAdvancedOptions = false;
  public deviceInfo;
  public referralCode = "";
  public app_version_num = "";
  public localeDate = "";
  model: any = {};

  //-----------------//
  public mapUrl: any;
  public phoneInfo: any;
  public appsInfo: any = [];
  public wiseOsAppInfo: any;
  public personInfo: any;
  public account: any;
  public settingPageText: any;
  public loadedLocationTime: any;
  public spinner = false;
  public db: DbBackup[] = [];
  //-----------//-DbBackup----------//

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    public commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.submitMsg = "";
    this.member = new Member();
    if (window.location.href.includes("edit-person")) {
      this.route.params.forEach((param) => {
        if (param && param.id) {
          this.userId = param.id;
        }
      });
      this.member = this.commonService.decryptData("member");
      this.personInfo = this.member;
      //this.getQrcode(1);
    }
    // this.getRoles();
  }

  ngOnInit() {
    const currentYear = new Date().getFullYear();
    this.form = this.fb.group({
      // email: ['', [Validators.required, Validators.minLength(3), Validators.pattern(Pattern.ALPHANUMERIC)]],
      // password: ['', [Validators.required, Validators.minLength(6)]],
      firstname: ["", { validators: [Validators.required] }],
      middlename: [""],
      lastname: ["", { validators: [Validators.required] }],
      // passcode: [''],
      // parentPin: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      dobDate: [
        "",
        {
          validators: [Validators.required],
        },
      ],
      dobMonth: [
        "",
        {
          validators: [Validators.required],
        },
      ],
      dobYear: [
        "",
        {
          validators: [Validators.required],
        },
      ],
      role: [""],
    });

    this.makeMonth();

    if (this.member && this.member.personId) {
      this.getDeviceInfo();
      this.patchForm();
    }
    console.log("person id ==== ", this.member.personId);
    // this.getDbFile();
  }
  ngOnDestroy() {
    this.commonService.errorMessage = "";
  }

  /**
   * @method checkValidity
   * @description this method checks date is valid or not
   */
  private checkValidity() {
    if (this.form.value.dobDate && this.form.value.dobMonth && this.form.value.dobYear) {
      const day = this.form.value.dobDate.length == 1 ? "0" + this.form.value.dobDate : this.form.value.dobDate;
      const month = this.form.value.dobMonth.length == 1 ? "0" + this.form.value.dobMonth : this.form.value.dobMonth;
      let year = this.form.value.dobYear.length == 2 ? this.form.value.dobYear : this.form.value.dobYear;
      let sdt = new Date();
      const yr = sdt.getFullYear();
      if (this.form.value.dobYear.length == 2) {
        let nyr = 20 + year;
        if (parseInt(nyr) <= yr) {
          year = parseInt(nyr);
        } else {
          nyr = 19 + year;
          year = parseInt(nyr);
        }
      }
      let flag = 0;
      if (this.form.value.dobMonth.length > 2) {
        flag++;
      }
      const dt = day + "/" + month + "/" + year;
      const isValidDate = flag
        ? moment(dt, "DD/MMMM/YYYY").format("DD/MM/YYYY")
        : moment(dt, "DD/MM/YYYY").format("DD/MM/YYYY");
      console.log(isValidDate);
      if (isValidDate === "Invalid date") {
        this.invalidDate = true;
        this.form.controls.dobDate.setErrors({ notValid: true });
      } else {
        this.invalidDate = false;
        this.form.controls.dobDate.setErrors(null);
      }
    } else {
      // if (this.form.controls.dobMonth.valid) {
      //     document.getElementById('day').focus();
      // }
      // if (this.form.controls.dobDate.valid) {
      //     document.getElementById('year').focus();
      // }
      this.invalidDate = false;
    }
  }

  /**
   * @method patchForm
   * @description this method patches the form the member data
   */
  private patchForm() {
    for (const i in this.member) {
      if (this.member[i] instanceof String) {
        this.member[i].trim();
      }
    }
    this.form.patchValue(this.member);
    this.makeMonth(this.form.value.dobYear);
    this.makeDay(this.form.value.dobYear, this.form.value.dobMonth);
  }

  /**
   * @method submit
   * @description this method submits the form data
   */
  public submit() {
    this.submitMsg = "";
    setTimeout(() => {
      if (this.form.valid) {
        const dob = this.form.value.dobMonth + "/" + this.form.value.dobDate + "/" + this.form.value.dobYear;
        this.form.value.dob = dob;
        this.form.value.passcodeExists = 1;
        const body = this.form.value;
        if (this.member && this.member.personId) {
          body.personId = this.member.personId;
          this.apiService.httpPost(`admin/accounts/${this.userId}/updateperson`, body).subscribe((response: any) => {
            this.navigatePrimary();
          });
        } else {
          this.apiService.httpPost("persons", body).subscribe((response: any) => {
            this.navigatePrimary();
          });
        }
      }
    }, 500);
  }

  /**
   * @method navigatePrimary
   * @description this method navigates to the primary  menu
   */
  private getDbFile() {
    console.log("my ======");
    this.apiService.httpGet("mobiledb/1223").subscribe((sub: any) => {
      console.log("sub ======", sub);
      if (sub && sub.statusCode === 200) {
        this.db = sub.data;
      }
      console.log("db data ====", this.db);
    });
  }
  /**
   * @method navigatePrimary
   * @description this method navigates to the primary  menu
   */
  private navigatePrimary() {
    //this.getPerson();
    setTimeout(() => {
      this.form.reset();
      //this.router.navigate(['/app/account']);
      window.history.back();
    }, 1000);
  }

  /**
   * @method toggleType
   * @param key: string
   * @description this method toggles the password type
   */
  public toggleType(key: string) {
    if (this[key] === "password") {
      this[key] = "text";
    } else {
      this[key] = "password";
    }
  }

  /**
   * @method getQrcode
   * @description this method gets the qrcode from the API
   */
  public getQrcode(clickValue?: number) {
    const body = {};
    this.apiService
      .httpGet(`admin/person/${this.member.personId}/getqrcode/${clickValue}`)
      .subscribe((response: any) => {
        this.qrCodeUrl = response.enrollData.qrCode;
        if (clickValue) {
          document.getElementById("openModal").click();
        }
        //
      });
  }

  /**
   * @method getRoles
   * @description this method fetches the person roles
   */
  public getRoles() {
    this.commonService.showSpinner();
    this.apiService.httpGet("persons/roles").subscribe((response: any) => {
      if (response && response.data) {
        this.roles = response.data;
        this.commonService.hideSpinner();
      }
    });
  }

  /**
   * @method getPerson
   * @description this method fetches the person detail
   */
  public getPerson() {
    this.commonService.showSpinner();
    this.apiService.httpGet("persons?requestFrom=1").subscribe((response: any) => {
      if (response && response.data) {
        const personData = response.data;
        this.commonService.encryptAndSaveData(personData, "members");
        this.commonService.hideSpinner();
      }
    });
  }

  public referralSubmit = "";
  referralRequired = false;
  public submitReferralCode() {
    let persons = [];
    persons.push({ personId: this.member.personId, code: this.model.referralCode });
    console.log(persons);
    this.apiService.httpPost("admin/accounts/referrals", { persons: persons }).subscribe((response: any) => {
      if (response) {
        this.member.referralCode = this.model.referralCode;
        this.referralSubmit = response.message;
      }
    });
  }

  /**
   * @method detectExtraSpace
   * @param controlName: string
   * @description this method detects the extra space and trims it
   */
  public detectExtraSpace(controlName: string) {
    setTimeout(() => {
      this.form.controls[controlName].patchValue(this.form.controls[controlName].value.trim());
    }, 2000);
  }

  /**
   * @method changeAccountStatus
   * @param type: number
   * @param status: string
   * @description this method changes the account status
   */
  public changeAccountStatus(type: number, personId: number, status?: string) {
    if (type === 1) {
      document.getElementById("disablePop").click();
    } else if (type === 3) {
      document.getElementById("deletePop").click();
    } else if (type === 4) {
      document.getElementById("uninstallPop").click();
    } else {
      this.updateStatus(2);
    }
  }

  /**
   * @method updateStatus
   * @param type: number
   * @description this method updates the status using api
   */
  public updateStatus(type: number) {
    this.submitMsg = "";
    this.apiService
      .httpGet(`admin/person/${this.member.personId}/disableperson?type=${type}`)
      .subscribe((response: any) => {
        if (response) {
          this.submitMsg = response.message;
          //this.getPerson();
          if (type !== 3) {
            this.setStatus();
            if (type === 4) {
              this.member.reenrollStatus = 1;
            }
          } else {
            this.commonService.cancelForm(this.form);
          }
        }
      });
  }

  /**
   * @method getDeviceInfo
   * @description this method gets the device info
   */
  public getDeviceInfo() {
    this.apiService.httpGet(`admin/devices/${this.userId}/${this.member.deviceId}`).subscribe((response: any) => {
      this.phoneInfo = response.data.deviceInfo;
      this.account = response.data.userInfo;

      let array = response.data.deviceApps.sort((a, b) => b.app_version_num - a.app_version_num);
      array.map((app) => {
        if (app.app_name.toLowerCase() === "launcher") {
          this.wiseOsAppInfo = app;
          if (this.phoneInfo.is_custom_sdk_device) {
            this.app_version_num = this.phoneInfo.wise_os_version;
          }
          const appExist = this.appsInfo.find((a) => a.app_id === app.app_id);
          if (!appExist) {
            this.appsInfo.push(app);
          }
          // this.wiseOsAppInfo.localeDate = moment(app.max).format("LL");
          // this.phoneInfo.localeDate = moment(this.phoneInfo.created_date).format("LL");
        } else {
          const appExist = this.appsInfo.find((a) => a.app_id === app.app_id);
          if (!appExist) {
            this.appsInfo.push(app);
          }
        }
      });
      if (this.appsInfo && this.appsInfo.length > 0) {
        let array = this.appsInfo.sort((a, b) => new Date(b.max).getTime() - new Date(a.max).getTime());
        this.localeDate = moment(array[0].max).format("LL");
      }
    });
  }

  /**
   * @method getDeviceInfo
   * @description this method fetches device info
   */
  public getPersonDeviceInfo(lat?: any, long?: any) {
    this.apiService.httpGet(`devices/${this.member.deviceId}`).subscribe((response: any) => {
      this.phoneInfo = response.data;
      this.getDeviceAppsInfo(); // Get device Apps Information
      //             this.mapUrl = this.sanitizer.
      // bypassSecurityTrustResourceUrl(`https://www.google.com/maps/embed/v1/place?key=AIzaSyAPPOIB0p50IES0xwu4Oc3bUy1Wa-rH2Ts
      //       &q=${lat}+${long}`);
    });
  }

  /**
   * @method getDeviceInfo
   * @description this method fetches device info
   */
  public getDeviceAppsInfo(lat?: any, long?: any) {
    this.apiService.httpGet(`devices/${this.member.deviceId}/apps`).subscribe((response: any) => {
      if (response.data.length > 0) {
        response.data.map((app) => {
          if (app.app_id === 1) {
            this.wiseOsAppInfo = app;
            this.wiseOsAppInfo.localeDate = moment(app.max).format("LL");
            this.phoneInfo.localeDate = moment(this.phoneInfo.created_date).format("LL");
          } else {
            const appExist = this.appsInfo.find((a) => a.app_id === app.app_id);
            this.localeDate = moment(app.max).format("LL");
            this.app_version_num = app.app_version_num;
            if (!appExist) {
              this.appsInfo.push(app);
            }
          }
        });
      }
      this.commonService.hideSpinner();
      this.spinner = false;
    });
  }

  /**
   * @method setStatus
   * @description this method updates the status locally
   */
  public setStatus() {
    this.member.status = !this.member.status;
  }
  /**
   * @method setStatus
   * @description this method updates the status locally
   */
  public downloadFile(enc: string) {
    this.downloadedFile(enc);
  }
  downloadedFile(data: any) {
    const blob = new Blob([data.db_file], { type: "application/octet-stream" });
    console.log("blob =====>", blob);
    // const url= window.URL.createObjectURL(blob);
    // window.open(url);
    // var url = window.URL.createObjectURL(blob);
    saveAs(blob, data.app_name + ".db");
    // window.open(url);
  }

  public makeDay(year?: number, month?: string) {
    console.log(year, month);
    const yr = year ? year : parseInt(moment().format("YYYY"));
    const mnth = month ? month : moment().format("MM");
    console.log(yr, mnth);
    let noOfDays = moment(yr + "/" + mnth, "YYYY/MM").daysInMonth();
    this.dobDays = [];
    for (let i = 1; i <= noOfDays; i++) {
      if (i < 10) {
        let j = "" + 0 + i;
        this.dobDays.push(j);
      } else {
        this.dobDays.push(i);
      }
    }
    return this.dobDays;
  }

  public makeMonth(yr?) {
    this.dobMonths = [];
    const currYear = moment().format("YYYY");
    const currMonth = moment().format("MM");
    if (yr == currYear) {
      for (let i = 1; i < currMonth; i++) {
        this.dobMonths.push(DateFields.months[i - 1]);
      }
    } else {
      this.dobMonths = DateFields.months;
    }
    this.makeDay(this.form.value.dobYear, this.form.value.dobMonth);
  }
}
