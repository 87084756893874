import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-credit-card-approval',
  templateUrl: './credit-card-approval.component.html',
  styleUrls: ['./credit-card-approval.component.scss']
})
export class CreditCardApprovalComponent implements OnInit {

  marked = false;
  theCheckbox = false;
  constructor() {
  
  }
  
  toggleVisibility(e){
    this.marked= e.target.checked;
  }

  ngOnInit() {
  }

}
