import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/services/api.service';
import { CommonService } from 'src/services/common.service';


@Component({
  selector: 'app-update-bug-record',
  templateUrl: './update-bug-record.component.html',
  styleUrls: ['./update-bug-record.component.scss']
})
export class UpdateBugRecordComponent implements OnInit {
  public form: FormGroup
  private Id;
  private data;
  public dropdowncss = 'effect'
  constructor(private fb: FormBuilder, private apiService: ApiService, public commonService: CommonService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params) {
        this.Id = params.id
      }
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      status: ['', Validators.required]
    })

    if (this.Id) {
      this.apiService.httpGet(`bugs/getSingleBug/${this.Id}`).subscribe((response: any) => {
        if (response.data.length > 0) {
          this.data = response.data[0]
          this.setValues()
        }
      })
    }
  }


  get formControl() {
    return this.form.controls
  }
  setValues() {
    if (this.data) {
      this.form.setValue({
        title: this.data.title,
        description: this.data.description,
        status: this.data.status
      });
    }
  }
  onSubmit() {
    let data = {
      title: this.form.get('title').value,
      description: this.formControl['description'].value,
      status: this.formControl['status'].value
    }
    console.log(data)
    this.apiService.httpPut(`bugs/updatebug/${this.Id}`, data).subscribe((response: any) => {
      console.log("update response ===>", `bugs/updatebug/${this.Id}`, response);
      if (response.message === "success") {
        this.router.navigate(['/layout/bug-list']);
      }
    });
  }
}
