import { ApiService } from "src/services/api.service";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import Chart from "chart.js";
import { keyframes } from "@angular/animations";
import { IUsesDashboardData } from "src/object/user.object";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  public dashboard;
  public callData = [];
  public msgData = [];
  startDate;
  endDate;
  public historicData;
  public time = "thisWeek";
  public legacy = true;
  public customSdk = true;
  public data: IUsesDashboardData = { activeUsers: 0, totalUsers: 0, familyAccounts: 0, personAccounts: 0 };
  constructor(private apiService: ApiService) {
    this.getUsersData();
    // this.getDashboard();
    // this.getHistoricData();
  }

  toggleFilter() {
    document.querySelector(".productList").classList.toggle("show");
    document.querySelector(".dismisser").classList.toggle("show");
  }

  ngOnInit() {}

  public getDashboard() {
    var envId = environment.stageId;
    if (envId == 2) {
      envId = 1;
    } else if (envId == 3) {
      envId = 2;
    }
    this.apiService.httpGet("admin/dashboard/" + envId).subscribe((response: any) => {
      this.dashboard = response.data;
      let callkey = Object.keys(this.dashboard.callsChartData);
      let msgkey = Object.keys(this.dashboard.msgChartData);

      for (let i = 0; i <= callkey.length; i++) {
        this.callData.push(this.dashboard.callsChartData[i]);
      }
      for (let i = 0; i <= msgkey.length; i++) {
        this.msgData.push(this.dashboard.msgChartData[i]);
      }
      setTimeout(() => {
        // this.createCallsChart();
        // this.createMsgsChart();
      }, 1000);
      // this.callData.push()
    });
  }

  public createCallsChart() {
    let ctx = document.getElementById("calls");
    let myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00"],
        datasets: [
          {
            label: "Calls",
            data: this.callData,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }

  public createMsgsChart() {
    let ctx = document.getElementById("msgs");
    let myChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00"],
        datasets: [
          {
            label: "Messages",
            data: this.msgData,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }

  public onLegacyCheck = (event: any) => {
    this.legacy = event.target.checked;
    this.getUsersData();
  };

  public onCustomsdkCheck = (event: any) => {
    this.customSdk = event.target.checked;
    this.getUsersData();
  };

  public onTimeChange = (event: any) => {
    this.time = event.target.value;
    this.getUsersData();
  };
  public getHistoricData(date?) {
    let body = {};
    if (this.startDate && this.endDate && !date) {
      body = {
        startDate: this.startDate.year + "-" + this.startDate.month + "-" + this.startDate.day,
        endDate: this.endDate.year + "-" + this.endDate.month + "-" + this.endDate.day,
      };
    } else if (date) {
    }

    this.apiService.httpPost("admin/dashboard/historic", body).subscribe((response: any) => {
      this.historicData = response.data;
      console.log(this.historicData);
    });
  }

  public getUsersData() {    
    if (this.customSdk || this.legacy) {
      this.apiService
        .httpGet(`admin/activeusers?time=${this.time}&legacy=${this.legacy}&customSdk=${this.customSdk}`)
        .subscribe((response: any) => {
          if (response && response.error === 0) {
            this.data = {
              activeUsers: response.activeUsers,
              totalUsers: response.totalUsers,
              familyAccounts: response.familyAccounts,
              personAccounts: response.personAccounts,
            };
          } else {
            this.data = { activeUsers: 0, totalUsers: 0, familyAccounts: 0, personAccounts: 0 };
          }
        });
    }else{

    }
  }
}
