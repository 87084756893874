import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET, Router } from '@angular/router';
import { IBreadcrumb } from 'src/object/bread-crumb.model';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.breadcrumbs = [];
  }

  /**
   * @ngOnInit method
   * @functionname ngOnInit
   * @description Load the necessary method call and data assignment on page load.
   * @returns void
  **/
  ngOnInit() {
    const root: ActivatedRoute = this.activatedRoute.root;
    this.breadcrumbs = this.getBreadcrumbs(root);
  }

  /**
   * @ngMethod getBreadcrumbs
   * @description Used to generate the breadcrumbs dynamically.
   * @param ActivatedRoute route
   * @param url
   * @param breadcrumbs
   */
  private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }
    for (const child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }
      if (!child.snapshot.data.hasOwnProperty('breadcrumb')) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }
      const routeURL: string = child.snapshot.url
        .map(segment => segment.path)
        .join('/');
      url += `/${routeURL}`;
      if (child.snapshot.data.breadcrumb.hasOwnProperty('multiLevel')) {
        const breadcrumb: IBreadcrumb = {
          label: child.snapshot.data.breadcrumb['label'],
          currentLabel: child.snapshot.data.breadcrumb['currentLabel'],
          params: child.snapshot.params,
          multiLevel: child.snapshot.data.breadcrumb.multiLevel,
          url: url,
        };
        breadcrumbs.push(breadcrumb);
      } else {
        const breadcrumb: IBreadcrumb = {
          label: child.snapshot.data.breadcrumb['label'],
          currentLabel: child.snapshot.data.breadcrumb['currentLabel'],
          params: child.snapshot.params,
          routerLink: child.snapshot.data.breadcrumb['routerLink'],
          url: url,
        };
        breadcrumbs.push(breadcrumb);
      }
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }

  /**
   * @ngMethod navigation
   * @description Used to navigate the page.
   * @param navigation url
   */
  public navigation(url) {
    this.router.navigate([url]);
  }
}
