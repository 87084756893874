import { Component, OnInit, HostListener } from "@angular/core";
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiService } from "src/services/api.service";
import { environment } from "src/environments/environment";
import { CommonService } from "src/services/common.service";
import { Router } from "@angular/router";
import { Pattern } from "src/constants/pattern";
import * as S3 from "aws-sdk/clients/s3";
import { MatDialog } from "@angular/material";
import { AlertComponent } from "src/app/ng-material components/alert/alert.component";

@Component({
  selector: "app-release-notes",
  templateUrl: "./release-notes.component.html",
  styleUrls: ["./release-notes.component.scss"],
})
export class ReleaseNotesComponent implements OnInit {
  public formArray = new FormArray([]);
  public form: FormGroup;
  public appform: FormGroup;
  public showForm = false;
  public releaseNotes: any;
  public note: any;
  public noteTypes = [];
  private appId = 0;
  public releasenotes = [];
  public uploadedFiles;
  public signedPath;
  public apkname = "";
  public stageId = 0;
  public qrcodeData = "";
  public showQR = false;
  public apkBucket = "";
  public deleteAppId = 0;
  public availAppId = 0;
  public progressValue = 0;
  private previousValue = 0;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    public commonService: CommonService,
    private dialog: MatDialog,
    private router: Router,
    private http: HttpClient
  ) {
    // document.getElementById('content').style.width = '800px'
  }

  ngOnInit() {
    this.form = new FormGroup({
      version: new FormControl("", {
        validators: [Validators.required, Validators.pattern(Pattern.NUMERIC)],
      }),
      versionStageID: new FormControl(""),
      notes: new FormArray([]),
      apk: new FormControl("", { validators: [Validators.required] }),
      checksum: new FormControl("", {}),
    });
    this.appform = new FormGroup({
      name: new FormControl("", { validators: [Validators.required] }),
      package: new FormControl("", { validators: [Validators.required] }),
      apkname: new FormControl("", { validators: [Validators.required] }),
      version: new FormControl("", {
        validators: [Validators.required, Validators.pattern(Pattern.NUMERIC)],
      }),
      versionStageID: new FormControl(""),
      notes: new FormArray([]),
      apk: new FormControl("", { validators: [Validators.required] }),
      checksum: new FormControl("", {}),
    });
    this.getNoteTypes();
    this.stageId = environment.stageId;
    if (this.stageId == 1) {
      this.apkBucket = "techlessdevapks";
    } else if (this.stageId == 2) {
      this.apkBucket = "techlessstageapks";
    } else if (this.stageId == 3) {
      this.apkBucket = "techlessprodapks";
    }

    // this.addNotes();
    this.getReleaseNotes();
  }

  private getNoteTypes() {
    this.apiService.httpGet("apps/noteTypes").subscribe((response: any) => {
      this.noteTypes = response.data;
    });
  }

  /**
   * @method addNotes
   * @description this method add's the notes to the form group
   */
  public addNotes(note?: any) {
    console.log(note);
    if (note) {
      this.appId = note.appId;
      this.apkname = note.apkname;
      this.note = note;
    }

    const notes = this.form.get("notes") as FormArray;
    const group = new FormGroup({
      type: new FormControl("", { validators: [Validators.required] }),
      note: new FormControl("", { validators: [Validators.required] }),
      apk: new FormControl("", { validators: [Validators.required] }),
    });
    notes.push(group);
    this.showForm = true;
  }

  /**
   * @method onConfirm
   * @description This method runs to open confirmation dialogue box when updating app if all fields are filled
   */
  public onConfirm(form: FormGroup) {
    const notes = this.form.get("notes") as FormArray;
    if (
      this.form.value.version &&
      this.form.value.notes[0].type != "" &&
      this.form.value.notes[0].note != "" &&
      this.form.value.checksum &&
      this.form.value.apk
    ) {
      const dialogref = this.dialog.open(AlertComponent, {
        data: {
          title: "Update App?",
          message: "Do you want to update this app?",
          description: "Updating this app will update this app on all devices.",
        },
      });
      dialogref.afterClosed().subscribe((result) => {
        console.log("result ===> ", result);
        if (result === true) {
          console.log("lol ===> Calling saveNotes()");
          this.saveNotes();
        }
      });
    }
  }

  /**
   * @method addAppNotes
   * @description this method add's the notes to the form group
   */
  public addAppNotes() {
    const notes = this.appform.get("notes") as FormArray;
    const group = new FormGroup({
      type: new FormControl("", { validators: [Validators.required] }),
      note: new FormControl("", { validators: [Validators.required] }),
      apk: new FormControl("", { validators: [Validators.required] }),
    });
    notes.push(group);
  }

  /**
   * @method saveNotes
   * @description this method saves the notes to the form group
   */
  public saveNotes() {
    console.log("hitted save notes ===>", this.form.value);
    // this.form.reset();
    this.form.patchValue({
      versionStageID: this.stageId,
    });
    setTimeout(() => {
      if (this.uploadedFiles != undefined && this.uploadedFiles.length > 0) {
        /*const formData: FormData = new FormData();
          formData.append('file', this.uploadedFiles[0], this.uploadedFiles[0].name);
          this.apiService.httpGet('apps/path/'+this.appId).subscribe((response: any) => {
            //console.log(response);
            //this.signedPath = response.data;
            this.signedPath = 'https://techlessdevapks.s3.amazonaws.com/launcher.apk?AWSAccessKeyId=AKIA6KEDRNVDB7CHNLVH&Content-Type=application%2Fvnd.android.package-archive&Expires=1599138649&Signature=Cl%2Buym3sdqDW2FFhMZWBy8NKegQ%3D';
            if(this.signedPath) {

              this.apiService.httpPutWithUrl(this.signedPath, formData).subscribe((res) => {
                //console.log(res);
                //if(res) {
                this.apiService.httpPost('apps/' + this.appId, this.form.value).subscribe((resp: any) => {
                  //console.log(resp);
                  this.commonService.showSuccessToaster(resp.message, 'Notes Added');
                  this.getReleaseNotes();
                  this.clearForm();
                });
                //}
              });
            }
          });*/
        this.commonService.showSpinner();
        const file = this.uploadedFiles.item(0);
        const contentType = file.type;
        const bucket = new S3({
          accessKeyId: "AKIA6KEDRNVDPVFTHCXS",
          secretAccessKey: "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",
          region: "us-east-1",
        });

        const params = {
          Bucket: this.apkBucket,
          Key: this.apkname,
          Body: file,
          ContentType: contentType,
        };
        if (this.appId == 1) {
          params["ACL"] = "public-read";
        }
        bucket
          .upload(params, (err, data) => {
            if (err) {
              this.commonService.showErrorToaster("Request Time out", "Error");
              document.getElementById("close-modal").click();
              console.log("ERROR: ", JSON.stringify(err));
              this.progressValue = 0;
              return false;
            } else {
              this.apiService.httpPost("apps/" + this.appId, this.form.value).subscribe((resp: any) => {
                //console.log(resp);
                this.progressValue = 0;
                this.commonService.showSuccessToaster(resp.message, "Notes Added");
                this.getReleaseNotes();
                this.clearForm();
              });
            }
            console.log("File Uploaded.", data);
            //return true;
          })
          .on("httpUploadProgress", (progress) => {
            this.progressValue = Math.round((progress.loaded / progress.total) * 100);
            if (this.progressValue === 100) {
              console.log("progress bar ====>", this.progressValue);
            }
            if (this.previousValue > this.progressValue) {
              this.commonService.showErrorToaster("Poor internet Connection", "Reconnecting");
            }
            this.previousValue = this.progressValue;
          });
      } else {
        this.apiService.httpPost("apps/" + this.appId, this.form.value).subscribe((resp: any) => {
          //console.log(resp);
          this.commonService.showSuccessToaster(resp.message, "Notes Added");
          this.getReleaseNotes();
          this.clearForm();
        });
      }
    }, 500);
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.appform.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  /**
   * @method saveNotes
   * @description this method saves the notes to the form group
   */
  public async saveApp() {
    //console.log(this.form.value);
    // this.form.reset();
    console.log(this.appform.valid);
    console.log(this.findInvalidControls());
    this.appform.patchValue({
      versionStageID: this.stageId,
    });
    //if(this.appform.valid) {
    setTimeout(async () => {
      if (this.uploadedFiles != undefined && this.uploadedFiles.length > 0) {
        let apkname = this.appform.get("apkname").value;
        var ext = /^.+\.([^.]+)$/.exec(apkname);
        var extension = ext == null ? "" : ext[1];

        var lastDot = apkname.lastIndexOf(".");
        if (lastDot == -1) {
          lastDot = apkname.length;
        }
        const apkFileName = apkname.substring(0, lastDot);
        if (extension == "" || extension != "apk") {
          extension = "apk";
        }
        var appfilename = apkFileName + "." + extension;
        // this.commonService.showSpinner();
        const file = this.uploadedFiles.item(0);
        const contentType = file.type;
        const bucket = new S3({
          accessKeyId: "AKIA6KEDRNVDPVFTHCXS",
          secretAccessKey: "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",
          region: "us-east-1",
        });
        const params = {
          Bucket: this.apkBucket,
          Key: appfilename,
          Body: file,
          ContentType: contentType,
        };

        await bucket
          .upload(params, (err, data) => {
            if (err) {
              this.commonService.showErrorToaster("Request Time out", "Error");
              document.getElementById("close-modal").click();
              console.log("ERROR: ", JSON.stringify(err));
              this.progressValue = 0;
              return false;
            } else {
              this.apiService.httpPost("apps/", this.appform.value).subscribe((resp: any) => {
                //console.log(resp);
                this.progressValue = 0;
                this.commonService.showSuccessToaster(resp.message, "New app added");
                this.getReleaseNotes();
                this.clearForm();
              });
            }
            console.log("File Uploaded.", data);
            //return true;
          })
          .on("httpUploadProgress", (progress) => {
            this.progressValue = Math.round((progress.loaded / progress.total) * 100);
            if (this.progressValue === 100) {
              console.log("progress bar ====>", this.progressValue);
            }
            if (this.previousValue > this.progressValue) {
              this.commonService.showErrorToaster("Poor internet Connection", "Reconnecting");
            }
            this.previousValue = this.progressValue;
          });
      } else {
        this.apiService.httpPost("apps/" + this.appId, this.form.value).subscribe((resp: any) => {
          //console.log(resp);
          this.commonService.showSuccessToaster(resp.message, "Notes Added");
          this.getReleaseNotes();
          this.clearForm();
        });
      }
    }, 500);
    //}
  }

  public fileChange(element) {
    this.uploadedFiles = element.target.files;
  }

  public getSignedURL(note: any) {}

  public setNote(note: any) {
    this.apiService.httpGet("apps/" + note.appId).subscribe((response: any) => {
      this.releasenotes = response.data;
      this.note.appName = note.appName;
    });
  }

  private closeModal() {
    document.getElementById("modalClose").click();
  }
  /**
   * @method clearData
   * @description this method clears the data
   */
  public clearForm() {
    this.form.reset();
    this.appform.reset();
    const notes = this.form.get("notes") as FormArray;
    notes.clear();
    const appnotes = this.appform.get("notes") as FormArray;
    appnotes.clear();
    this.closeModal();
  }
  /**
   * @method logout
   * @description this method logout the user from the app
   */
  public logout() {
    localStorage.clear();
    this.router.navigate(["/admin"]);
  }

  /**
   * @method removeNotes
   * @description this method removes the data
   */
  public removeNotes(index: number) {
    const notes = this.form.get("notes") as FormArray;
    notes.removeAt(index);
  }

  /**
   * @method getControls
   * @description this method gets the form array controls
   */
  public getControls() {
    const formArray = (this.form.get("notes") as FormArray).controls;
    return formArray;
  }

  /**
   * @method getAppControls
   * @description this method gets the form array controls
   */
  public getAppControls() {
    const formArray = (this.appform.get("notes") as FormArray).controls;
    return formArray;
  }

  /**
   * @method getReleaseNotes
   * @description this method fetches the previous release notes
   */
  private getReleaseNotes() {
    this.apiService.httpGet("apps").subscribe((response: any) => {
      this.releaseNotes = response.data;
    });
  }

  public sendSyncPush() {
    this.apiService.httpGet("apps/update/0").subscribe((response: any) => {
      console.log(response);
    });
  }

  /**
   * @method getReleaseNotes
   * @description this method fetches the previous release notes
   */
  public getAppsQRCode() {
    this.apiService.httpGet("admin/apps/enroll/" + this.stageId).subscribe((response: any) => {
      this.qrcodeData = response.data;
      if (this.qrcodeData) {
        this.showQR = true;
        //document.getElementById('openModal').click();
      }
    });
  }

  /**
   * @method deleteAppConfirmation
   * @description this method opens confirmation popup for deleting app
   */
  public confirmdeleteapp(note) {
    this.deleteAppId = note.appId;
    document.getElementById("showDeletePopup").click();
  }

  public deleteapp(status) {
    console.log(this.deleteAppId);
    this.apiService.httpDelete("apps/" + this.deleteAppId + "/" + status).subscribe((resp: any) => {
      //console.log(resp);
      this.deleteAppId = 0;
      this.commonService.showSuccessToaster(resp.message, "App Updated");
      this.getReleaseNotes();
      if (status == 1) {
        document.getElementById("deleteappclose").click();
      } else if (status == 0) {
        document.getElementById("restoreappclose").click();
      }
    });
  }

  /**
   * @method MakeAppAvailableConfirmation
   * @description this method opens confirmation popup for Making app available
   */
  public confirmappavailability(note) {
    this.availAppId = note.appId;
    document.getElementById("showAvailPopup").click();
  }

  /**
   * @method removeAppAvailabilityConfirmation
   * @description this method opens confirmation popup for removing app from availability
   */
  public confirmremoveavailabilityapp(note) {
    this.availAppId = note.appId;
    document.getElementById("showRemoveAvailPopup").click();
  }

  public makeappavailable(status) {
    console.log(this.deleteAppId);
    this.apiService.httpPost("apps/available/" + this.availAppId + "/" + status, {}).subscribe((resp: any) => {
      //console.log(resp);
      this.availAppId = 0;
      this.commonService.showSuccessToaster(resp.message, "App Updated");
      this.getReleaseNotes();
      if (status == 1) {
        document.getElementById("makeavailappclose").click();
      } else if (status == 0) {
        document.getElementById("removeavailappclose").click();
      }
    });
  }

  /**
   * @method syncAppsConfirmation
   * @description this method opens confirmation popup for syncing apps
   */
  public confirmsyncapps() {
    document.getElementById("showSyncappPopup").click();
  }

  public syncapps() {
    this.apiService.httpGet("apps/update/0").subscribe((response: any) => {
      console.log(response);
      document.getElementById("syncappappclose").click();
    });
  }

  /**
   * @method deleteAppConfirmation
   * @description this method opens confirmation popup for deleting app
   */
  public confirmrestoreapp(note) {
    this.deleteAppId = note.appId;
    document.getElementById("showRestorePopup").click();
  }
}
