import { CommonService } from './../../services/common.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/services/api.service';
import { Pattern } from 'src/constants/pattern';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-create-id',
    templateUrl: './create-id.component.html',
    styleUrls: ['./create-id.component.scss']
})
export class CreateIdComponent implements OnInit, OnDestroy {

    public form: FormGroup;
    public notEqual = false;
    public submitMsg = '';
    constructor(private fb: FormBuilder,
        private router: Router,
        private apiService: ApiService,
        public commonService: CommonService) { }

    ngOnInit() {
        this.submitMsg = '';
        this.form = this.fb.group({
            username: ['', { validators: [Validators.required, Validators.pattern(Pattern.EMAIL_REGEX)] }],
            firstname: ['', { validators: [Validators.required, Validators.minLength(2)] }],
            // middlename: [''] {validators: ,
            lastname: ['', { validators: [Validators.required] }],
            password: ['', { validators: [Validators.required, Validators.minLength(6)] }],
            repeatPassword: [''],
            usertype: ['']
        });
        this.form.valueChanges.subscribe(() => {
            this.checkValidatity();
        })
    }


    /**
     * @method createId
     * @description this method submits the form to db
     */
    public createId() {
        this.checkValidatity('s');
        setTimeout(() => {
            var usertype = 1;
            if(environment.stageId == 2) {
              usertype = 1;
            }
            else if(environment.stageId == 3) {
              usertype = 2;
            }
            this.form.patchValue({
              usertype: usertype
            });
            if (this.form.valid && !this.notEqual) {
                this.commonService.showSpinner();
                this.apiService.httpPost('admin/accounts/create', this.form.value).subscribe((response: any) => {
                    if (response) {
                        // this.commonService.hideSpinner();
                        // this.commonService.showSuccessToaster(response.message, '');
                        this.submitMsg = response.message;
                        setTimeout(() => {
                            this.commonService.cancelForm();
                        }, 2000);
                    }
                });
            }
        }, 500);
    }

    /**
     * @method checkValidatity
     * @description this method checks for password validity
     */
    public checkValidatity(submit?) {
        if (submit) {
            this.form.controls.repeatPassword.markAsDirty();
        }
        if (this.form.value.password !== this.form.value.repeatPassword) {
            this.notEqual = true;
        } else {
            this.notEqual = false;
        }
    }


    ngOnDestroy() {
        this.commonService.errorMessage = '';
    }
}
