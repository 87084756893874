import { ActivatedRoute } from '@angular/router';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from 'src/services/api.service';
import { CommonService } from 'src/services/common.service';
import { Pattern } from 'src/constants/pattern';

@Component({
    selector: 'app-contact-info',
    templateUrl: './contact-info.component.html',
    styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit, OnDestroy {

    public form: FormGroup;
    public account;
    public submitMsg = '';
    public userId = 0;
    constructor(private fb: FormBuilder, private apiService: ApiService, public commonService: CommonService, private route: ActivatedRoute) { 
        this.route.params.forEach((param)=>{
            if(param && param.id) {
              this.userId = param.id;
              this.getAccountInfo();
            }
          })
    }

    ngOnInit() {
        this.submitMsg = '';
        this.form = this.fb.group({
            phone: ['', {
                validators: [Validators.required, Validators.minLength(10),
                Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s.]{1}[/0-9]{3}[-\s.]{1}[/0-9]{4}|^[+]*[0-9]{10}/)]
            }],
            email: ['', { validators: Validators.compose([Validators.required, Validators.pattern(Pattern.EMAIL_REGEX)]), }],
            address: ['', { validators: [Validators.required] }],
            firstname: ['', { validators: [Validators.required] }],
            lastname: ['', { validators: [Validators.required] }],

            city: ['', { validators: [Validators.required, Validators.pattern(Pattern.ALPHA)] }],
            state: ['', { validators: [Validators.required, Validators.pattern(Pattern.ALPHA)] }],
            zipcode: ['', { validators: [Validators.required, Validators.pattern(Pattern.NUMERIC)], }]
        });

        // this.getAccountInfo();
    }

    ngOnDestroy() {
        this.commonService.errorMessage = '';
    }

    public getAccountInfo() {
        this.apiService.httpGet(`admin/accounts/${this.userId}`).subscribe((response: any) => {
            this.account = response.data;
            this.form.setValue({
                firstname: this.account.firstname ? this.account.firstname.trim() : this.account.billingFirstname.trim(),
                lastname: this.account.lastname ? this.account.lastname.trim() : this.account.billingLastname.trim(),

                phone: this.account.phone ? this.account.phone.trim() : this.account.billingPhone.trim(),
                email: this.account.contactEmail ? this.account.contactEmail.trim() : this.account.billingEmail.trim(),
                address: this.account.address ? this.account.address.trim() : this.account.billingAddress.trim(),
                city: this.account.city ? this.account.city.trim() : this.account.billingCity.trim(),
                state: this.account.state ? this.account.state.trim() : this.account.billingState.trim(),
                zipcode: this.account.zipcode ? this.account.zipcode.trim() : this.account.billingZipcode.trim(),
            }, { onlySelf: true });

        });
    }


    /**
     * @method submit
     * @description this method submits the form data
     */
    public submit() {
        this.submitMsg = '';

        setTimeout(() => {
            if (this.form.valid) {
                this.apiService.httpPost(`admin/accounts/${this.userId}/updatecontactinfo`, this.form.value).subscribe((response: any) => {
                    this.submitMsg = response.message;
                    setTimeout(() => {
                        this.commonService.cancelForm();
                    }, 2000);
                });
            }
        }, 500);

    }

    /**
     * @method detectExtraSpace
     * @param controlName: string
     * @description this method detects the extra space and trims it
     */
    public detectExtraSpace(controlName: string) {
        setTimeout(() => {
            this.form.controls[controlName].patchValue(this.form.controls[controlName].value.trim());
        }, 2000);
    }

}
