
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BillinInfo } from 'src/object/billing-info.object';
import { CommonService } from 'src/services/common.service';
import { ApiService } from 'src/services/api.service';
import { Pattern } from 'src/constants/pattern';

@Component({
    selector: 'app-billing',
    templateUrl: './billing.component.html',
    styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit, OnDestroy {

    public form: FormGroup;
    private billingInfo = new BillinInfo();
    public submitMsg = '';
    public countries = [];
    public userId = 0;
    constructor(private fb: FormBuilder, public commonService: CommonService, private apiService: ApiService, private router: Router,private route: ActivatedRoute) { 
        this.route.params.forEach((param)=>{
            if(param && param.id) {
              this.userId = param.id;
              this.getBillingInfo();
            }
          })
    }

    ngOnInit() {
        this.getCountries();
        this.submitMsg = '';
        this.form = this.fb.group({
            billingFirstname: ['', { validators: [Validators.required, Validators.pattern(Pattern.ALPHANUMERIC)] }],
            billingLastname: ['', { validators: [Validators.required, Validators.pattern(Pattern.ALPHANUMERIC)] }],

            billingAddress: ['', { validators: [Validators.required, Validators.pattern(Pattern.ALPHANUMERIC), Validators.maxLength(200)] }],
            // billingAddress2: ['', [Validators.maxLength(200), Validators.pattern(Pattern.ALPHANUMERIC)]],
            billingCity: ['', { validators: [Validators.required, Validators.pattern(Pattern.ALPHA), Validators.maxLength(75)] }],
            billingState: ['', { validators: [Validators.required, Validators.pattern(Pattern.ALPHA), Validators.maxLength(2)] }],
            billingZipcode: ['', {
                validators: [Validators.required, Validators.minLength(5), Validators.maxLength(5),
                Validators.pattern(Pattern.NUMERIC)]
            }],
            billingCountry: ['United States', { validators: [Validators.required, Validators.pattern(Pattern.ALPHA), Validators.maxLength(75)], }]
        });
        this.getBillingInfo();
    }

    ngOnDestroy() {
        this.commonService.errorMessage = '';
    }

    /**
     * @method getBillingInfo
     * @description this method gets the billing info
     */
    private getBillingInfo() {
        this.apiService.httpGet(`admin/accounts/${this.userId}/billinginfo`).subscribe((response: any) => {
            if (response && response.data) {
                this.billingInfo = response.data;
                this.form.patchValue(response.data);
            }
        });
    }

    /**
     * @method getCountries
     * @description this method gets the billing info
     */
    private getCountries() {
        this.apiService.httpGet('countries').subscribe((response: any) => {
            if (response && response.data) {
                this.countries = response.data;
            }
        });
    }



    /**
     * @method updateBillingInfo
     * @description this method updates the billing info
     */
    public updateBillingInfo() {
        this.submitMsg = '';

        setTimeout(() => {
            let contactInfo = {
                billingFirstname: this.billingInfo.billingFirstname,
                billingLastname: this.billingInfo.billingLastname,
                billingEmail: this.billingInfo.billingEmail,
                billigPhone: this.billingInfo.billingPhone
            }

            let body = { ...contactInfo, ...this.form.value };
            if (this.form.valid) {
                this.apiService.httpPost(`admin/accounts/${this.userId}/updatebillinginfo`, body).subscribe((response: any) => {
                    if (response) {
                        this.submitMsg = response.message;
                        setTimeout(() => {
                            this.commonService.cancelForm();
                        }, 2000)
                    }
                });
            }
        }, 500);
    }
}
