import {
    Directive,
    Inject,
    Optional, Host,
    ComponentRef,
    ViewContainerRef,
    ComponentFactoryResolver,
    Input, OnInit, ElementRef
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { FORM_ERRORS } from '../constants/form-errors';
import { FormSubmitDirective } from './form-submit.directive';
import { merge, Observable, EMPTY, fromEvent } from 'rxjs';
import { ControlErrorComponent } from '../shared/control-error/control-error.component';
import { ControlErrorContainerDirective } from './control-error-container.directive';
import { shareReplay } from 'rxjs/operators';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[formControl], [formControlName]'
})

export class ControlErrorsDirective implements OnInit {
    public ref: ComponentRef<ControlErrorComponent>;
    public container: ViewContainerRef;
    public submit$: Observable<Event>;
    public blurd$: Observable<Event>;
    @Input() public customErrors = {};
    // public blur$ = Observable<Event>;

    constructor(
        private vcr: ViewContainerRef,
        private resolver: ComponentFactoryResolver,
        @Optional() controlErrorContainer: ControlErrorContainerDirective,
        @Inject(FORM_ERRORS) private errors,
        @Optional() @Host() private form: FormSubmitDirective,
        private host: ElementRef<HTMLFormElement>,
        private controlDir: NgControl) {
        // this.blur$ = fromEvent(this.element, 'blur').pipe(shareReplay(1))
        this.container = controlErrorContainer ? controlErrorContainer.vcr : this.vcr;
        this.submit$ = this.form ? this.form.submit$ : EMPTY;
        this.blurd$ = this.form ? fromEvent(this.element, 'blur').pipe(shareReplay(1)) : EMPTY;
    }


    // Getter to get form element
    get element() {
        return this.host.nativeElement;
    }
    ngOnInit() {

        merge(
            this.submit$, this.blurd$
        ).pipe().subscribe((v) => {
            const controlErrors = this.control.errors;
            if (controlErrors) {
                const firstKey = Object.keys(controlErrors)[0];
                const getError = this.errors[firstKey];
                const text = this.customErrors[firstKey] || getError(controlErrors[firstKey]);
                this.setError(text);
            } else if (this.ref) {
                this.setError(null);
            }
        });

    }


    // Getter for getting control
    get control() {
        return this.controlDir.control;
    }

    /**
     * @method setError
     * @param text: string
     * @description this method used to set the error for the formcontrol
     */
    private setError(text: string) {
        if (!this.ref) {
            const factory = this.resolver.resolveComponentFactory(ControlErrorComponent);
            this.ref = this.container.createComponent(factory);
        }

        this.ref.instance.text = text;
    }


}

