import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { DateFields } from "./../constants/pattern";
import { ApiService } from "./api.service";
import { timestamp } from "rxjs/operators";
import { FormGroup } from "@angular/forms";
import { Injectable, HostListener } from "@angular/core";
import * as CryptoJS from "crypto-js";
import { Router } from "@angular/router";
import { User } from "../object/user.object";
import { Observable, Subject } from "rxjs";
declare var moment: any;

@Injectable({
  providedIn: "root",
})
export class CommonService {
  public windowSize: number;
  public menuName: string;
  public personName: string;
  public personInfo: User = new User();
  public hideTopBar = false;
  public showTopMenu = true;
  public initialMsg = "";
  public personData: Array<User> = [];
  public spinnerText: any;
  public isDeveloper = 0;
  public errorMessage = "";
  public dobYears = [];
  public lastYear = parseInt(moment().subtract(102, "years").format("YYYY"));
  private storageSub = new Subject();

  constructor(private spinner: NgxSpinnerService, private toastr: ToastrService, private router: Router) {
    if (localStorage.getItem("person")) {
      this.personInfo = this.decryptData("person");
    }
  }
  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  /**
   * @method setLocalStorage
   * @description this method set localStorage
   */
  public setLocalStorage(name: string, value: string) {
    localStorage.setItem(name, value);
    this.storageSub.next({ name: name, value: value });
  }
  // // getter that returns person information
  public personDetail(): User {
    return (this.personInfo = this.decryptData("person"));
  }

  public clearPerson() {
    this.personInfo = new User();
  }

  /**
   * @method validateForm
   * @param form: FormGroup
   * @description this method used to validate form
   */
  public validateForm(form: FormGroup) {
    for (const i in form.controls) {
      if (form.controls[i].invalid) {
        form.controls[i].markAsDirty();
        form.controls[i].markAsTouched();
      }
    }
  }

  /**
   * @method setLoginData
   * @description this method sets the creds from login response
   */
  public setLoginData(authData: any) {
    localStorage.setItem("access-token", this.set("123456$#@$^@1ERF", authData));
    // localStorage.setItem('access-token', authData.AccessToken);
    // localStorage.setItem('refresh-token', authData.RefreshToken);
  }

  /**
   * @method set
   * @param keys: string
   * @param value: string
   * @description The set method is use for encrypt the value.
   */
  public set(keys: string, value: string) {
    const key = CryptoJS.enc.Utf8.parse(keys);
    const iv = CryptoJS.enc.Utf8.parse(keys);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  }

  /**
   * @method encrypt
   * @param value: any
   * @description The set method is use for encrypt the value.
   */
  public encrypt(value: any) {
    const data = JSON.stringify(value);
    const encrypted = CryptoJS.AES.encrypt(data, "masterEncrypt#@2020");
    return encrypted.toString();
  }

  /**
   * @method decrypt
   * @param value: string
   * @description The get method is use for decrypt the value.
   */
  public decrypt(value: string) {
    const decrypted = CryptoJS.AES.decrypt(value, "masterEncrypt#@2020");
    let data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
    return data;
  }
  /**
   * @method get
   * @param keys: string
   * @param value: string
   * @description The get method is use for decrypt the value.
   */
  public get(keys: string, value: string) {
    const key = CryptoJS.enc.Utf8.parse(keys);
    const iv = CryptoJS.enc.Utf8.parse(keys);
    const decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  /**
   * @method showSpinner
   * @description this method shows the spinner
   */
  public showSpinner() {
    this.spinner.show();
    document.body.style.overflowY = "hidden";
  }

  /**
   * @method hideSpinner
   * @description this method shows the spinner
   */
  public hideSpinner() {
    document.body.style.overflowY = "auto";
    this.spinner.hide();
  }

  /**
   * @method showSuccessToasterencryptAndSaveData
   * @param title: string
   * @param message: string
   * @description this method shows the success toast
   */
  public showSuccessToaster(message: string, title: string) {
    this.toastr.success(message, title);
  }

  /**
   * @method showErrorToaster
   * @param title: string
   * @param message: string
   * @description this method shows the error toast
   */
  public showErrorToaster(message: string, title: string) {
    this.toastr.error(message, title);
  }

  /**
   * @get decryptData
   * @description this variable gets the decrypted value
   */
  public get decryptedToken() {
    const token = this.get("123456$#@$^@1ERF", localStorage.getItem("access-token"));
    return token;
  }

  /**
   * @method encryptAndSaveData
   * @description this method encrypts the data and saves in local
   * @param data: any
   * @param name: string
   */
  public encryptAndSaveData(data: any, name: string) {
    const tempData = JSON.stringify(data);
    localStorage.setItem(name, this.set("123456$#@$^@1ERF", tempData));
  }

  /**
   * @method decryptData
   * @description this method decrypts the data
   */
  public decryptData(name: string) {
    const data = this.get("123456$#@$^@1ERF", localStorage.getItem(name));
    const tempData = JSON.parse(data);
    return tempData;
  }

  /**
   * @method navigate
   * @param url: string
   * @description this method used to navigate
   */
  public navigate(url: string) {
    this.router.navigate([url]);
  }

  /**
   * @method goBack
   * @description this method navigates back to dashboard
   */
  public goBack() {
    window.history.back();
  }

  /**
   * @method dateTimeFormat
   * @param datetimestamp: string
   * @description this method gets the time format
   */
  public dateTimeFormat(datetimestamp: number) {
    const tDate = moment.unix(datetimestamp).utc().format("DD-MM-YYYY HH:mm");
    const mDate = moment(tDate, "DD-MM-YYYY HH:mm").calendar(null, {
      sameDay: "[Today], hh:mm",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      lastDay: "[Yesterday]",
      lastWeek: "dddd DD",
      sameElse: "MMM DD",
    });
    return mDate;
  }

  /**
   * @method dateMessageFormat
   * @param datetimestamp: string
   * @description this method gets the time format
   */
  public dateMessageFormat(datetimestamp: number) {
    const tDate = moment.unix(datetimestamp).utc().format("DD-MM-YYYY HH:mm");
    const mDate = moment(tDate, "DD-MM-YYYY HH:mm").calendar(null, {
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      lastDay: "[Yesterday]",
      lastWeek: "dddd DD",
      sameElse: "dddd DD",
    });
    return mDate;
  }

  /**
   * @method dateMessageFormat
   * @description this method gets the time format
   */
  public datePaymentFormat(duration: number): string {
    const tDate = moment(new Date()).utc().format("DD-MM-YYYY HH:mm");
    const mDate = moment(tDate, "DD-MM-YYYY HH:mm").add(duration, "days").format("MMMM D, YYYY");
    return mDate;
  }

  /**
   * @method timeMessageFormat
   * @param datetimestamp: string
   * @description this method gets the time format
   */
  public timeMessageFormat(datetimestamp: number) {
    const tDate = moment.unix(datetimestamp).utc().format("DD-MM-YYYY HH:mm");
    const mDate = moment(tDate, "DD-MM-YYYY HH:mm").calendar(null, {
      sameDay: "h:mm",
      nextDay: "h:mm",
      nextWeek: "h:mm",
      lastDay: "h:mm",
      lastWeek: "h:mm",
      sameElse: " h:mm",
    });
    return mDate;
  }

  /**
   * @method cancelForm
   * @param form: FormGroup
   * @description this resets the form
   */
  public cancelForm(form?: FormGroup) {
    window.history.back();
    this.showTopMenu = true;
    this.checkRoute();
  }

  /**
   * @method checkRoute
   * @description this method checks for the route and toggles the top menu bar
   */
  public checkRoute() {
    if (window.location.href.search("home") !== -1) {
      this.showTopMenu = false;
      this.personInfo = new User();
    } else if (window.location.href.search("account") !== -1) {
      this.showTopMenu = false;
    } else {
      this.showTopMenu = true;
    }
  }

  /**
   * @method convertNumberToString
   * @param num: any
   * @description this method converts number to string and returns the String
   */
  public convertNumberToString(num: any) {
    const a = [
      "",
      "One ",
      "Two ",
      "Three ",
      "Four ",
      "Five ",
      "Six ",
      "Seven ",
      "Eight ",
      "Nine ",
      "Ten ",
      "Eleven ",
      "Twelve ",
      "Thirteen ",
      "Fourteen ",
      "Fifteen ",
      "Sixteen ",
      "Seventeen ",
      "Eighteen ",
      "Nineteen ",
    ];
    const b = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];

    if ((num = num.toString()).length > 9) {
      return "overflow";
    }
    let n = [];
    n = ("000000000" + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) {
      return;
    }

    let str = "";
    str += n[1] != 0 ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore " : "";
    str += n[2] != 0 ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh " : "";
    str += n[3] != 0 ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand " : "";
    str += n[4] != 0 ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred " : "";
    str += n[5] != 0 ? (str !== "" ? "and " : "") + (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) : "";
    return str;
  }

  get makeYear() {
    let dobYears = [];
    let curYear = parseInt(moment().format("YYYY"));
    for (curYear; curYear > this.lastYear; curYear--) {
      dobYears.push(curYear);
    }
    return dobYears;
  }

  get makeMonth() {
    return DateFields.months;
  }
}
