import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/services/api.service';
import { CommonService } from 'src/services/common.service';

@Component({
  selector: 'app-add-bug-record',
  templateUrl: './add-bug-record.component.html',
  styleUrls: ['./add-bug-record.component.scss']
})
export class AddBugRecordComponent implements OnInit {

  public form: FormGroup
  public notes = []
  constructor(private fb: FormBuilder, private apiService: ApiService, public commonService: CommonService, private router: Router) { }

  ngOnInit() {
    this.form = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      status: ['backlog', Validators.required]
    })
  }
  onSubmit() {
    let data = {
      title: this.form.get('title').value,
      description: this.formControl['description'].value,
      status: this.formControl['status'].value
    }

    console.log(data)
    if (this.form.valid) {
      this.apiService.httpPost('bugs/createbug', data).subscribe((response: any) => {
        if (response.message == "success") {
          this.router.navigate(['/layout/bug-list']);
        }
      });
    }
  }

  get formControl() {
    return this.form.controls
  }
}