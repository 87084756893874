import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/services/api.service';

@Component({
  selector: 'app-account-communications',
  templateUrl: './account-communications.component.html',
  styleUrls: ['./account-communications.component.scss']
})
export class AccountCommunicationsComponent implements OnInit {
  public form: FormGroup
  public textNullCheck = ""
  public getNote = []
  public userId
  //child css properties will change in nav Componentchange dynamically 
  public General = "pointer"
  public Comunication = "selected pointer"
  public dynamicClass = ""
  constructor(private fb: FormBuilder, private apiService: ApiService,private route: ActivatedRoute) {
    this.route.params.forEach((param)=>{
      if(param && param.id) {
        this.userId = param.id;
        console.log(this.userId)
      }
    })
   }

  ngOnInit() {
    this.form = this.fb.group({
      medium: ['N/A', Validators.required],
      note: ['', Validators.required]
    })
    this.getValues();
    this.onChanges();
  }
// /personId
  onClear() {
    this.form.get('note').reset()
  }
  clear() {
    this.form.patchValue({
      medium: 'N/A',
      note: ''
    })
  }

  getValues() {
    this.apiService.httpGet(`accounts/getNotes/${this.userId}`).subscribe((response: any) => {
      this.getNote = response.data.reverse()
    });
  }
  onChanges(): void {
    this.form.valueChanges.subscribe(val => {
      if(this.form.get('note').value){
        this.dynamicClass = "text-danger"
      }else{
        this.dynamicClass = ""
      }
    });
  }
  onSubmit() {
    let data = {
      medium: this.form.get('medium').value,
      note: this.form.get('note').value,
      date: new Date(),
      userId : this.userId
    }

    if (this.form.valid && this.userId) {
      this.apiService.httpPost('accounts/addSingleNote', data).subscribe((response: any) => {
        if (response.message === "success") {
          this.clear()
          this.getValues();
        }
      })
    }
  }
  get formControl() {
    return this.form.controls
  }
}
