import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { AddOsVersionsComponent } from "src/app/ng-material components/add-os-versions/add-os-versions.component";
import { ApiService } from "src/services/api.service";
import { CommonService } from "src/services/common.service";
export interface IOSVersion {
  datetime: number;
  filename: string;
  id: string;
  romtype: string;
  size: number;
  url: string;
  version: string;
  updatedBy: string;
}
@Component({
  selector: "app-os-versions",
  templateUrl: "./os-versions.component.html",
  styleUrls: ["./os-versions.component.scss"],
})
export class OsVersionsComponent implements OnInit {
  public data: IOSVersion[];
  constructor(private dialog: MatDialog, private apiService: ApiService, private commonService: CommonService) {}
  ngOnInit(): void {
    this.getAllVersions();
  }

  /**
   * openForm
   */
  public openForm() {
    const dialogref = this.dialog.open(AddOsVersionsComponent, {
      disableClose: true,
    });
    dialogref.afterClosed().subscribe((result) => {
      this.getAllVersions();
    });
  }
  public getAllVersions() {
    this.apiService.httpGet("customossdk/all?getName=1").subscribe((response: any) => {
      this.commonService.hideSpinner();
      if (response && response.data && Object.keys(response.data).length > 0) {
        console.log("response ===>", response);
        this.data = response.data;
      } else {
        this.commonService.showErrorToaster(response.message, "error");
      }
    });
  }
}
