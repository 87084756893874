import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { Pattern } from 'src/constants/pattern';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    public form: FormGroup;
    public adminLogin = false;
    constructor(private router: Router,
        private apiService: ApiService,
        public commonService: CommonService,
        private fb: FormBuilder) {

        const token = localStorage.getItem('access-token');
        if (!this.adminLogin && token) {
        
            this.router.navigate(['layout/accounts']);
        }


    }

    ngOnInit() {
        this.form = this.fb.group({
            username: ['', { validators: [Validators.required, Validators.pattern(Pattern.EMAIL_REGEX)] }],
            password: ['', { validators: Validators.required }]
        });
    }

    /**
     * @method onLoginSubmit
     * @description this method used to validate and submit the user creds to server for authentication
     */
    public onLoginSubmit() {
        setTimeout(() => {

            if (this.form.valid) {
                this.commonService.showSpinner();
                const body = this.form.value;
                body.requestFrom = 0;
                let url = '';
                url = 'admin/login';
                this.apiService.httpPost(url, body).subscribe((response: any) => {
                    if (response && response.accessToken && !response.error) {
                        this.commonService.isDeveloper = response.data.isDeveloper;
                        localStorage.setItem('isDev', response.data.isDeveloper);
                        this.commonService.setLoginData(response.accessToken);
                        console.log(response.accessToken)
                        this.router.navigate(['layout/accounts']);
                        
                    }
                    if(response && response.error !== 0){
                        this.commonService.showErrorToaster(response.message, "Invalid Request")
                        this.commonService.hideSpinner();
                    }
                }, err => {
                    this.commonService.hideSpinner();
                });
            } else {
                this.commonService.validateForm(this.form);
            }
        }, 500);
    }


    /**
     * @method gotoCreateId
     * @description this method navigates to create id page
     */
    public gotoCreateId() {
        this.router.navigate(['create-id']);
    }
}
