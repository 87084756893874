import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LaunchControlComponent } from "./launch-control.component";
import { RouterModule, Routes } from "@angular/router";
import { TracksComponent } from "./tracks/tracks.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularMaterialModule } from "../ng-material components/angular-material.module";
import { AppSharedModule } from "src/shared/app-shared/app-shared.module";
import { AttachUsersComponent } from "./attach-users/attach-users.component";
import { AttachOsComponent } from "./attach-os/attach-os.component";
import { CreateTrackComponent } from "./create-track/create-track.component";
import { AttachAppsComponent } from "./attach-apps/attach-apps.component";
import { ViewComponent } from "./view/view.component";
import { NgDynamicBreadcrumbModule } from "ng-dynamic-breadcrumb";

const routes: Routes = [
  { path: "", redirectTo: "tracks", pathMatch: "full" },
  {
    path: "tracks",
    component: TracksComponent,
  },
  {
    path: "users/:id",
    component: AttachUsersComponent,
  },
  {
    path: "os/:id",
    component: AttachOsComponent,
  },
  {
    path: "apps/:id",
    component: AttachAppsComponent,
  },
  {
    path: "view/:id",
    component: ViewComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("./view/view.module").then((m) => m.ViewModuleModule),
      },
    ],
  },
];
@NgModule({
  declarations: [
    LaunchControlComponent,
    CreateTrackComponent,
    TracksComponent,
    AttachUsersComponent,
    AttachOsComponent,
    AttachAppsComponent,
    ViewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppSharedModule,
    AngularMaterialModule,
    NgDynamicBreadcrumbModule,
    RouterModule.forChild(routes),
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [CreateTrackComponent],
})
export class LaunchControlModuleModule {}
