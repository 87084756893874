import { NoContentComponent } from './../no-content/no-content.component';
import { BreadcrumbComponent } from './../breadcrumb/breadcrumb.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlErrorsDirective } from 'src/directives/controls.directive';
import { FocusInvalidDirective } from 'src/directives/focus-invalid.directive';
import { FormSubmitDirective } from 'src/directives/form-submit.directive';
import { PhoneMaskDirective } from 'src/directives/phone-mask.directive';
import { ControlErrorContainerDirective } from 'src/directives/control-error-container.directive';
import { ControlErrorComponent } from '../control-error/control-error.component';
import { QRCodeModule } from 'angularx-qrcode';
import { AngularMaterialModule } from 'src/app/ng-material components/angular-material.module';




@NgModule({
    declarations: [
        BreadcrumbComponent,
        ControlErrorsDirective,
        FocusInvalidDirective,
        FormSubmitDirective,
        PhoneMaskDirective,
        ControlErrorContainerDirective,
        ControlErrorComponent,
        NoContentComponent],

    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        QRCodeModule,
        AngularMaterialModule,

    ],
    exports: [
        BreadcrumbComponent,
        FormsModule,
        ReactiveFormsModule,
        ControlErrorsDirective,
        FocusInvalidDirective,
        FormSubmitDirective,
        PhoneMaskDirective,
        NoContentComponent,
        ControlErrorContainerDirective,
        ControlErrorComponent,
        QRCodeModule,
        AngularMaterialModule,
    ]
})
export class AppSharedModule { }
