import { Component, OnInit } from '@angular/core';
import { User } from 'src/object/user.object';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/services/common.service';
import { ApiService } from 'src/services/api.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-accounts-general',
  templateUrl: './accounts-general.component.html',
  styleUrls: ['./accounts-general.component.scss']
})
export class AccountsGeneralComponent implements OnInit {

  public members: Array<User> = [];
    public persons = [];
    public qrCodeUrl = '';
    public account;
    public billing = false;
    public userId: number;
    model: any = {};
    //child css properties will change in nav Componentchange dynamically 
    public General = "selected pointer"
    public Comunication = "pointer"

    public deviceInfo: any; 
    public  accountInfoSubmit = '';
    constructor(private router: Router, public commonService: CommonService, public apiService: ApiService,  private route: ActivatedRoute) {
        this.route.params.forEach((param)=>{
          if(param && param.id) {
            this.userId = param.id;
          }
        })
    }

    ngOnInit() {
        this.getAccountInfo();
    }

    /**
     * @method getAccountInfo
     * @description this method gets account info
     */
    public getAccountInfo() {
        this.apiService.httpGet(`admin/accounts/${this.userId}`).subscribe((response: any) => {
            this.account = response.data;
            this.model.username = this.account.contactEmail  ? this.account.contactEmail : this.account.billingEmail;
            this.model.password = this.account.password  ? this.account.password : '';

            localStorage.setItem('comp',this.account.isCompAccount);
            this.persons = [...this.account.persons];
            this.commonService.personData = response.data.persons;

        });
    }

    /**
     * @method navigateTo
     * @param route: string
     * @description this method navigates to the account module
     */
    public navigateTo(route: string) {
        this.router.navigate(['layout/accounts/' + route, this.account.userId]);
    }

    /**
     * @method navigateToCart
     * @param route: string
     * @description this method navigates to the account module
     */
    public navigateToCart(route: string) {
        this.router.navigate([route]);

    }

    /**
     * @method getQrcode
     * @description this method gets the qrcode from the API
     */
    public getQrcode(personId: number) {
        const body = {
            plan: this.account.planId
        };
        this.apiService.httpPost(`amapi/qrcode/` + personId, body).subscribe((response: any) => {
            this.qrCodeUrl = response.enrollData.qrCode;
            document.getElementById('openModal').click();
        });
    }


    /**
     * @method unenrollDevice
     * @description this method unenrolls the device for person
     */
    public unenrollDevice(personId: number) {
        const body = {
        };
        this.apiService.httpPost(`amapi/deletedevice/` + personId, body).subscribe((response: any) => {
            this.getAccountInfo();
        });
    }

    public referralSubmit  = '';
    public submitReferralCode() {
        let persons = [];
        this.account.persons.forEach((person)=>{
            persons.push({personId:person.personId,code:person.referralCode})
        });
        console.log(persons)
        this.apiService.httpPost('admin/accounts/referrals',{persons:persons}).subscribe((response:any)=>{
            if(response)  {
                this.referralSubmit = response.message;
                this.getAccountInfo();
            }
        });
    }

    public compSubmit  = '';
    public changeCompAccount(comp: number) {
        this.apiService.httpPost(`admin/accounts/${this.userId}/compaccount`,{isCompAccount:comp}).subscribe((response:any)=>{
            if(response)  {
                this.compSubmit = response.message;
                this.getAccountInfo();

            }
        });
    }


    public subSubmit  = '';
    public confirmSub = false;
    public cancelSubs(subs: number) {
        this.commonService.errorMessage  = '';
        this.apiService.httpPost(`admin/accounts/${this.userId}/updatesubscriptions`,{cancelSubscriptions:subs}).subscribe((response:any)=>{
            if(response)  {
                this.subSubmit = response.message;
                this.confirmSub =  false;
                this.getAccountInfo();

            }
        });
    }


    public delSubmit  = '';
    public confirmDel = false;
    public deleteUser() {
        this.commonService.errorMessage  = '';
        this.apiService.httpDelete(`admin/accounts/${this.userId}/delete`).subscribe((response:any)=>{
            if(response)  {
                this.delSubmit = response.message;
                this.confirmDel =  false;
                this.router.navigate(['layout/accounts/']);

            }
        });
    }

    public manualBilling = {
        chargeAmount:0,
        refundAmount:0,
        creditAmount:0
    }
    public billingSubmit = '';
    public submitManualBilling() {
        this.apiService.httpPost(`admin/accounts/${this.userId}/instantbilling`,this.manualBilling).subscribe((response:any)=>{
            this.billingSubmit = response.message;

        })
    }


    /**
     * @method submitAccountInfo
     * @description this method submits the accountinfo
     */
    public submitAccountInfo(isValid) {
        if(isValid) {
            this.commonService.errorMessage = '';
            this.apiService.httpPost(`admin/accounts/${this.userId}/edit`,this.model).subscribe((response:any)=>{
                this.accountInfoSubmit = response.message;

            },(err)=> {

            },()=> {
                if(this.commonService.errorMessage) {
                    this.accountInfoSubmit = this.commonService.errorMessage
                }
            })
        }

    }


    /**
     * @method renewPerson
     * @description this method renews the person
     */
    public renewPerson(personId) {
        console.log(personId);

        this.apiService.httpGet(`admin/accounts/${this.userId}/renew/${personId}`).subscribe((response: any) => {
            console.log(response);
            this.getAccountInfo();
        });
    }
    public routeToTechless() {
        window.location.href = 'https://techless.com/store/wisephone';
    }
}
