import { Component, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/services/common.service';
import { ApiService } from 'src/services/api.service';
import { EventEmitter } from 'events';

@Component({
  selector: 'app-account-nav-tabs',
  templateUrl: './account-nav-tabs.component.html',
  styleUrls: ['./account-nav-tabs.component.scss']
})
export class AccountNavTabsComponent implements OnInit {

  public account;
  public userId: number;
  public selectedGeneral = "selected pointer"
  public selectedComunication = "pointer"
  @Input() general = ''
  @Input() Comunication = ''



  constructor(public router: Router, public commonService: CommonService, public apiService: ApiService, private route: ActivatedRoute) {
    this.route.params.forEach((param) => {
      if (param && param.id) {
        this.userId = param.id;
      }
    })
  }
  ngOnInit() {
    this.getAccountInfo();
  }
  /**
   * onSelectGeneral
   */
  public onSelectGeneral() {

    this.router.navigate(['/layout/accounts/' + this.account.userId + '/general']);
  }
  /**
   * onSelectComunication
   */
  public onSelectComunication() {
    this.router.navigate(['/layout/accounts/' + this.account.userId + '/communications']);
  }
  /**
  * @method getAccountInfo
  * @description this method gets account info
  */
  public getAccountInfo() {
    this.apiService.httpGet(`admin/accounts/${this.userId}`).subscribe((response: any) => {
      this.account = response.data;

      localStorage.setItem('comp', this.account.isCompAccount);
      this.commonService.personData = response.data.persons;

    });
  }

}
