import { Component, OnInit } from '@angular/core';
import { User } from 'src/object/user.object';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/services/common.service';
import { ApiService } from 'src/services/api.service';

@Component({
  selector: 'app-account-heading',
  templateUrl: './account-heading.component.html',
  styleUrls: ['./account-heading.component.scss']
})
export class AccountHeadingComponent implements OnInit {
  
  public account;
  public userId: number;
  
  constructor(private router: Router, public commonService: CommonService, public apiService: ApiService,  private route: ActivatedRoute) {
    this.route.params.forEach((param)=>{
      if(param && param.id) {
        this.userId = param.id;
      }
    })
}

ngOnInit() {
  this.getAccountInfo();
}

/**
* @method getAccountInfo
* @description this method gets account info
*/
public getAccountInfo() {
  this.apiService.httpGet(`admin/accounts/${this.userId}`).subscribe((response: any) => {
      this.account = response.data;

      localStorage.setItem('comp',this.account.isCompAccount);
      this.commonService.personData = response.data.persons;

  });
}

}
