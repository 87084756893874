export class Member {
    dateCreated = '';
    dateModified = '';
    deviceId = 0;
    firstname = '';
    lastname = '';
    middlename = '';
    personId = 0;
    relation = '';
    status = 0 || false;
    email = '';
    dob = '';
    month = '';
    day = '';
    year = '';
    dobMonth = '';
    dobYear = '';
    dobDate = '';
    reenrollStatus = 0;
    referralCode='';
    referralBonus = 0;
}
