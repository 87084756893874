import { CommonService } from "./../../../services/common.service";
import { ApiService } from "./../../../services/api.service";
import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { MatDialog } from "@angular/material";
import { CopyContactsComponent } from "src/app/ng-material components/copy-contacts/copy-contacts.component";

@Component({
  selector: "app-accounts",
  templateUrl: "./accounts.component.html",
  styleUrls: ["./accounts.component.scss"],
})
export class AccountsComponent implements OnInit {
  public accounts = [];
  public searchQueryChanged: Subject<string> = new Subject<string>();
  public search = "";
  public stageId = 0;
  page = 1;
  pageSize = 10;
  totalAccounts = 0;
  constructor(private apiService: ApiService, public commonService: CommonService, private dialog: MatDialog) {
    this.commonService.isDeveloper = parseInt(localStorage.getItem("isDev"));
  }

  ngOnInit() {
    this.stageId = environment.stageId;
    this.getAccountList();
    this.searchQueryChanged.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((model) => {
      this.search = model;
      this.getAccountList();
    });
  }

  public getAccountList(page?: number) {
    console.log(page);
    let usertype = 1;
    if (this.stageId == 3) {
      usertype = 2;
    }
    this.apiService
      .httpGet(`admin/accounts?sort=3&search=${this.search}&page=${this.page}&usertype=${usertype}`)
      .subscribe((response: any) => {
        this.accounts = response.data;
        this.totalAccounts = response.totalAccounts;
      });
  }

  /**
   * @method callContactList
   * @description this method call the contact list
   */
  public searchAccountList(query: string) {
    this.searchQueryChanged.next(query);
  }
  /**
   * @method openCopyContactDialogue
   * @description this method will open open CopyContact Dialogue
   */
  public openCopyContactDialogue() {
    const dialogref = this.dialog.open(CopyContactsComponent, {
      disableClose: true,
      // height: "722px",
      maxHeight: "100vh",
    });
    dialogref.afterClosed().subscribe((result) => {
      console.log("result  ====>", result);
    });
  }
}
