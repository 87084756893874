import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/services/common.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  public buttonName = 'Menu';
  public menu;
  constructor(public commonService: CommonService, private route: ActivatedRoute) {

  }

  ngOnInit() {
      // this.menuFunction();
      this.commonService.checkRoute();
  }

  onActivate(event) {
      window.scroll(0, 0);
  }

}
