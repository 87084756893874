import { SelectionModel } from "@angular/cdk/collections";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ApiService } from "src/services/api.service";
import { CommonService } from "src/services/common.service";
import { IPersons, ITracks } from "../interfaces";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { AlertComponent } from "src/app/ng-material components/alert/alert.component";
import { NgDynamicBreadcrumbService } from "ng-dynamic-breadcrumb";

@Component({
  selector: "app-attach-users",
  templateUrl: "./attach-users.component.html",
  styleUrls: ["./attach-users.component.scss"],
})
export class AttachUsersComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    "select",
    "userId",
    "username",
    "useremail",
    "deviceId",
    "personId",
    "firstname",
    "lastname",
    "attachedwith",
  ];
  dataSource: MatTableDataSource<IPersons>;
  selection = new SelectionModel<IPersons>(true, []);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  private trackId;
  constructor(
    private commonService: CommonService,
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService
  ) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params) {
        this.trackId = params.id;
        this.getAllUsers();
      }
    });
  }

  ngAfterViewInit() {
    this.ngDynamicBreadcrumbService.updateBreadcrumb([
      { label: "Launch control", url: "/layout/launch-control" },
      { label: "Users", url: `/layout/launch-control/view/${this.trackId}/users` },
      { label: "Attach Users", url: `/layout/launch-control/users/${this.trackId}` },
    ]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isAllSelected() {
    if (this.selection && this.selection.selected) {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      const trackname = this.dataSource.data.filter((row) => row.track_name).length;
      const totSelected = Math.abs(numRows - trackname);
      return numSelected === totSelected;
    }
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) =>
          row.track_name ? this.selection.deselect(row) : this.selection.select(row)
        );
  }

  getAllUsers() {
    this.apiService.httpGet("releasetrack/os/persons").subscribe((response: any) => {
      this.commonService.hideSpinner();
      if (response.statusCode === 200) {
        this.dataSource = new MatTableDataSource(response.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        this.commonService.showErrorToaster(response.message, "Opss!");
      }
    });
  }
  attachUsers() {
    if (this.selection.selected) {
      const dialogref = this.dialog.open(AlertComponent, {
        data: {
          title: "Attach person to track",
          message: "Are you sure you want to attach this person?",
          description: `Please select the person you want to attach to this track and the corresponding OS build that the person will work on. 
          This action will assign the person to the selected track and grant them access to the associated OS build. 
          Please confirm your decision by clicking the 'OK' button below.`,
        },
      });
      dialogref.afterClosed().subscribe((result) => {
        if (result === true) {
          const arrayOfIds = this.selection.selected.map((obj) => obj.id);
          const data = {
            t_id: parseInt(this.trackId),
            p_id: arrayOfIds,
          };
          this.apiService.httpPost("releasetrack/persons/attach", data).subscribe((response: any) => {
            this.commonService.hideSpinner();
            if (response.statusCode === 200) {
              this.commonService.showSuccessToaster("Successfully Attached!", "Success");
              this.router.navigate(["layout/launch-control/view/" + this.trackId]);
            } else {
              this.commonService.showErrorToaster(response.message, "Opss!");
            }
          });
        }
      });
    }
  }
  isRowDisabled(index: any): boolean {
    console.log("Index === >>> ", index);

    return this.dataSource.filter.includes(index.toString());
  }
  // .includes(index);
}
