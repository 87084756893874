import { DomSanitizer } from '@angular/platform-browser';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BillinInfo } from 'src/object/billing-info.object';
import { CommonService } from 'src/services/common.service';
import { ApiService } from 'src/services/api.service';
import { User } from 'src/object/user.object';
import { Pattern } from 'src/constants/pattern';
import { environment } from 'src/environments/environment';

declare var gtag: any
@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    public isStep1Complete = false;
    public spfUrl = '';
    private accessToken = '';
    private billingInfo = new BillinInfo();
    public planDetail: any;
    public submitButton = true;
    private isCreated = '';
    public rfCode = '';
    public codeApplied = '';
    public tax = 0;
    public failure = false;
    public isMobile = false;
    public ccValue = '';
    public countries = [];
    public userId = 0;
    public stageId = 1;
    public shippingTitle = '';
    public shippingAmount = 0;
    public paymentStatus = false;
    public taxPerc = 0;
    public showSubmitButton = false;

    constructor(private fb: FormBuilder,
        public commonService: CommonService,
        public apiService: ApiService,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute,
        private router: Router) {
        this.planDetail = this.commonService.decryptData('plan-detail');
        this.route.queryParams.forEach((param) => {
            if (param && param.failure) {
                this.failure = true;
            }
        });
        this.route.params.forEach((param)=>{
            if(param && param.id) {
              this.userId = param.id;
              this.getBillingInfo();
            }
          })
        this.checkTokenInfo();
    }

    ngOnInit() {
	this.stageId = environment.stageId;
        this.getCountries();
        this.isStep1Complete = false;
        // if (this.isMobile) {
        //     document.body.style.width = '100% !important'
        // } else {
        //     document.getElementById('content').style.width = '900px';

        // }
        let accountValue: Partial<User> = new User();
        if (!this.accessToken || this.isCreated) {
            accountValue = this.commonService.decryptData('account-id');
        }
        this.form = this.fb.group({
            billingFirstname: [accountValue.firstname, { validators: [Validators.required, Validators.minLength(3)] }],
            billingLastname: [accountValue.lastname, { validators: [Validators.required, Validators.minLength(1)] }],
            billingEmail: [accountValue.username, { validators: [Validators.required, Validators.pattern(Pattern.EMAIL_REGEX)] }],
            billingPhone: ['', {
                validators: [Validators.required, Validators.minLength(10),
                Validators.pattern(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s.]{1}[/0-9]{3}[-\s.]{1}[/0-9]{4}|^[+]*[0-9]{10}/)]
            }],
            billingAddress: ['', { validators: [Validators.required, Validators.pattern(Pattern.ALPHANUMERIC), Validators.maxLength(200)] }],
            // billingAddress2: ['', [Validators.maxLength(200), Validators.pattern(Pattern.ALPHANUMERIC)]],
            billingCity: ['', { validators: [Validators.required, Validators.pattern(Pattern.ALPHA), Validators.maxLength(75)] }],
            billingState: ['', { validators: [Validators.required, Validators.pattern(Pattern.ALPHA), Validators.maxLength(2)] }],
            billingZipcode: ['', {
                validators: [Validators.required, Validators.minLength(5), Validators.maxLength(5),
                Validators.pattern(Pattern.NUMERIC)]
            }],
            billingCountry: ['United States', { validators: [Validators.required, Validators.pattern(Pattern.ALPHA), Validators.maxLength(75)], }]

        });

    }

    ngOnDestroy() {
        if (this.isMobile) {
            document.body.style.width = '100% !important'
        } else {
            document.getElementById('content').style.width = '600px';

        }
        this.isStep1Complete = false;
        localStorage.removeItem('isCreated');
        // localStorage.removeItem('plan-detail');
        this.isCreated = '';
        this.failure = false;
        this.commonService.errorMessage = '';

    }
    /**
     * @method getCountries
     * @description this method gets the billing info
     */
    private getCountries() {
        this.apiService.httpGet('countries').subscribe((response: any) => {
            if (response && response.data) {
                this.countries = response.data;
            }
        });
    }
    /**
     * @method submit
     * @description this method submits the user info
     */
    public submit() {
        setTimeout(() => {

            if (this.form.valid) {
                this.isStep1Complete = true;
                let accountValue: any;
                if (!this.billingInfo.billingEmail && !this.accessToken) {
                    accountValue = this.commonService.decryptData('account-id');
                    accountValue.username = this.form.value.billingEmail;
                }
                const plan = {
                    planId: this.planDetail.id,
                    unitAmount: this.planDetail.price,
                    quantity: this.planDetail.devices,
                    duration: this.planDetail.duration,
                    total: this.planDetail.price * this.planDetail.devices,
                    taxAmount: this.tax,
                    shippingCharge: this.shippingTitle,
                    shippingAmount: this.shippingAmount,
                    amount: (this.planDetail.price * this.planDetail.devices) + this.tax + this.shippingAmount,
                    plan: this.planDetail.name,
                    subscriptionDate: this.planDetail.subscriptionDate
                };
                const userInfo = { ...this.form.value, ...plan };
                console.log(userInfo)
                let url = '';
                url = this.billingInfo.billingEmail || this.accessToken ? `admin/accounts/${this.userId}/addperson` : 'payments/init';
                this.apiService.httpPost(url, userInfo).subscribe((response: any) => {
                    if (response ) {
                        // this.spfUrl = response.data.formUrl;
                        // this.transactionId = response.data.transactionId;
                        this.router.navigate(['/layout/accounts',this.userId,'detail'])
                        // this.isStep1Complete = true;
                        // gtag('event', 'conversion', { 'send_to': 'AW-671417343/ckZzCOPIts0BEP-HlMAC', 'transaction_id': this.transactionId });
                    }
                });
            }
        }, 500);

    }



    /**
     * @method callLoader
     * @description this methods turns on the loader for payment gateway
     */
    public callLoader() {

        // this.commonService.spinnerText = this.sanitizer.bypassSecurityTrustHtml(`Your payment is processing please wait.
        // <br> Do not click any button / Do not refresh the page.`);
        // this.commonService.showSpinner();
        // this.submitButton = false;
    }

    /**
     * @method checkTokenInfo
     * @description this method checks for the access token
     */
    private checkTokenInfo() {
        const token = localStorage.getItem('access-token');
        this.isCreated = localStorage.getItem('isCreated');
        this.accessToken = token;
        if (token) {
            this.getBillingInfo();
        }
        else {
          this.showSubmitButton = true;
        }
    }

    /**
     * @method getShippingCharges
     * @param country: any
     * * @description this method fetchs the tax info from api
     */
    public billingCountryChanged(country) {
      //console.log(country);
      this.getShippingCharges(country, this.planDetail.devices);
    }


    /**
     * @method getBillingInfo
     * @decription this method gets the billing info of that user
     */

    private getBillingInfo() {
        this.apiService.httpGet(`admin/accounts/${this.userId}/billinginfo`).subscribe((response: any) => {
            if (response && response.data) {
                this.billingInfo = response.data;
                if (this.billingInfo) {
                  /*if(this.billingInfo.billingEmail == '') {
                    let accountValue = this.commonService.decryptData('account-id');
                    this.billingInfo.billingEmail = accountValue.username;
                  }*/
                    this.form.patchValue(this.billingInfo);
                    //this.getTaxInfo(this.billingInfo.billingZipcode);
                    this.getShippingCharges(this.billingInfo.billingCountry, this.planDetail.devices);
                    this.getTaxInfo(this.billingInfo.billingState);
                }
                if(this.billingInfo.billingCountry == '') {
                  if(this.form.controls.billingCountry.value != undefined && this.form.controls.billingCountry.value != '') {
                    this.getShippingCharges(this.form.controls.billingCountry.value, this.planDetail.devices);
                  }
                }
            }
            else {
              this.showSubmitButton = true;
            }
        });
    }



    /**
     * @method getTaxInfo
     * @param zipcode: any
     * @description this method fetchs the tax info from api
     */
    public getTaxInfo(code?: any) {
        if (code.length >= 2) {
            const body = {
                state: code
            };
            this.apiService.httpPost('payments/gettax', body).subscribe((response: any) => {
                if (response && response.data) {
                    this.taxPerc = response.data.taxAmount ? response.data.taxAmount : 0;
                    let price = this.planDetail.price;
                    const num = ((price * this.planDetail.devices) + this.shippingAmount) * this.taxPerc;
                    this.tax = Math.round((num + Number.EPSILON) * 100) / 100;
                }
            });
        }
    }

    /**
     * @method applyRefferalCode
     * @param refferalCode: string
     * @desccription this method used to apply refferal code
     */
    public applyRefferalCode() {
        const body = {
            referralCode: this.rfCode
        };
        this.apiService.httpPost('payments/applyreferral', body).subscribe((response: any) => {
            if (response.message) {
                this.codeApplied = response.message;
            }
        });
    }

    /**
     * @method getShippingCharges
     * @param country: any
     * @param quantity: any
     * @description this method fetchs the tax info from api
     */
    public getShippingCharges(country, quantity) {
        if (country != '') {
            const body = {
                country: country,
                quantity: quantity
            };
            this.shippingTitle = '';
            this.shippingAmount = 0;
            this.apiService.httpPost('payments/getshipping', body).subscribe((response: any) => {
                this.showSubmitButton = true;
                if (response && response.data) {
                  console.log(response.data);
                  if(response.data.title != undefined && response.data.title != '') {
                    this.shippingTitle = response.data.title;
                    this.shippingAmount = response.data.amount;
                  }
                  console.log(this.taxPerc);
                  if(this.taxPerc > 0) {
                    let price = this.planDetail.price;
                    const num = ((price * this.planDetail.devices) + this.shippingAmount) * this.taxPerc;
                    this.tax = Math.round((num + Number.EPSILON) * 100) / 100;
                  }
                    /*let price = this.planDetail.duration == 365 ? this.planDetail.price : this.planDetail.monthlyPrice;
                    const num = (price * this.planDetail.devices) * (response.data.taxAmount ? response.data.taxAmount : 0);
                    this.tax = Math.round((num + Number.EPSILON) * 100) / 100;*/
                }
            });
        }
    }

    public getValue(dt: string) {
        let val = dt;
        this.ccValue = val.replace(/^(\d{2})(\d{2})/, '$1/$2');
        document.getElementById('bc')['value'] = this.ccValue;


        if (dt.length === 5) {
            const re = /[0-9]{2}\/[0-9]{2}/;

            const match = dt.match(re);
            if (match) {
                const edt = dt.split('/');
                console.log(edt);
                document.getElementById('bce')['value'] = edt[0] + edt[1];
            }
            else {
                document.getElementById('bce')['value'] = dt;
            }
            // document.forms['form']['billing-cc-number'].value = dt;
        }
        else {
            document.getElementById('bce')['value'] = dt;
        }

    }
}
