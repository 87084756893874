
export class BillinInfo {
    billingFirstname = '';
    billingLastname = '';
    billingEmail = '';
    billingPhone = '';
    billingAddress = '';
    billingCity = '';
    billingState = '';
    billingZipcode = '';
    billingCountry = '';
    username = '';
    firstname = '';
    lastname = '';
}
