import { AddPersonComponent } from "./admin/add-person/add-person.component";
import { CartComponent } from "./admin/package/cart/cart.component";
import { PricingComponent } from "./admin/package/pricing/pricing.component";
import { BillingComponent } from "./admin/billing/billing.component";
import { ContactInfoComponent } from "./admin/contact-info/contact-info.component";
import { ReportsComponent } from "./reports/reports.component";
import { CreditCardApprovalComponent } from "./credit-card-approval/credit-card-approval.component";
import { BugListComponent } from "./bugs/bug-list/bug-list.component";
import { AddBugRecordComponent } from "./bugs/add-bug-record/add-bug-record.component";
import { FinanceComponent } from "./finance/finance.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AccountsGeneralComponent } from "./admin/accounts/accounts-general/accounts-general.component";
import { AccountCommunicationsComponent } from "./admin/accounts/account-communications/account-communications.component";
import { AccountsComponent } from "./admin/accounts/accounts.component";
import { LayoutComponent } from "./layout/layout/layout.component";
import { ReleaseNotesComponent } from "./admin/release-notes/release-notes.component";
import { LoginComponent } from "./../auth/login/login.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ApiService } from "src/services/api.service";
import { CommonService } from "src/services/common.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ApiInterceptor } from "src/interceptors/interceptor";
import { AuthGuard } from "src/guards/auth.guard";
import { CreateIdComponent } from "src/auth/create-id/create-id.component";
import { UpdateBugRecordComponent } from "./bugs/update-bug-record/update-bug-record.component";
import { OrderComponent } from "./order/order.component";
import { CustomSdkComponent } from "./custom-sdk/custom-sdk.component";
import { ApkReleasesComponent } from "./custom-sdk/apk-releases/apk-releases.component";
import { OsVersionsComponent } from "./custom-sdk/os-versions/os-versions.component";
import { LaunchControlComponent } from "./launch-control/launch-control.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
  },

  {
    path: "layout",
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "release-notes",
        component: ReleaseNotesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "dashboard",
        component: DashboardComponent,
      },
      {
        path: "finance",
        component: FinanceComponent,
      },
      {
        path: "accounts",
        component: AccountsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "cc-approval",
        component: CreditCardApprovalComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "create-order",
        component: OrderComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "bug-list",
        component: BugListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "bug-list/add",
        component: AddBugRecordComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "bug-list/update",
        component: UpdateBugRecordComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "reports",
        component: ReportsComponent,
        canActivate: [AuthGuard],
      },
      // {
      //   path:'package/plans/:id/:userId', component:PricingComponent,
      //   canActivate:[AuthGuard],

      // },
      // {
      //   path:'package/cart/:id', component:CartComponent,
      //   canActivate:[AuthGuard],

      // },

      {
        path: "accounts/edit-contact/:id",
        component: ContactInfoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "accounts/billing-info/:id",
        component: BillingComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "accounts/create-account",
        component: CreateIdComponent,
        canActivate: [AuthGuard],
      },

      {
        path: "accounts/edit-person/:id",
        component: AddPersonComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "accounts/:id/general",
        component: AccountsGeneralComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "accounts/:id/communications",
        component: AccountCommunicationsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "sdk",
        component: CustomSdkComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: "apk-releases",
            component: ApkReleasesComponent,
            canActivate: [AuthGuard],
          },
          {
            path: "os-versions",
            component: OsVersionsComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'launch-control',
        component: LaunchControlComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('./launch-control/launch-control-module.module').then(m => m.LaunchControlModuleModule)
          },
        ]
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    ApiService,
    CommonService,
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
  ],
})
export class AppRoutingModule {}
