// eslint-disable-next-line import/no-unresolved
import { UpdateApkVersionsComponent } from "src/app/ng-material components/update-apk-versions/update-apk-versions.component";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { CreateAppComponent } from "src/app/ng-material components/create-app/create-app.component";
import { AlertComponent } from "src/app/ng-material components/alert/alert.component";
import { ApiService } from "src/services/api.service";
import { CommonService } from "src/services/common.service";
import { environment } from "src/environments/environment";
import ICustomSdk from "../custom-sdk";

@Component({
  selector: "app-apk-releases",
  templateUrl: "./apk-releases.component.html",
  styleUrls: ["./apk-releases.component.scss"],
})
export class ApkReleasesComponent implements OnInit {
  public stage = "dev";
  public NoteAppName = '';
  public releaseNotes: ICustomSdk[] = [];
  public releaseNote: any;
  public AlignLeft = "table";

  constructor(private dialog: MatDialog, private apiService: ApiService, public commonService: CommonService) {
    if (environment.stageId == 2) {
      this.stage = "Stage";
    } else if (environment.stageId == 3) {
      this.stage = "Prod";
    } else {
      this.AlignLeft = "table";
    }
  }
  ngOnInit(): void {
    this.getReleaseNotes();
  }
  public setNote(app: any) {
    this.apiService.httpGet("customos/apps/" + app.appId).subscribe((response: any) => {
      this.commonService.hideSpinner();
      this.releaseNote = response.data;
      this.NoteAppName = app.appName
    });
  }
  public updateApp(appId: number, appName: string) {
    const dialogref = this.dialog.open(UpdateApkVersionsComponent, {
      disableClose: true,
      height: "722px",
      maxHeight: "100vh",
      data: {
        appId: appId,
        appName: appName,
      },
    });
    dialogref.afterClosed().subscribe((result) => {
      this.getReleaseNotes();
    });
  }
  public createApp() {
    const dialogref = this.dialog.open(CreateAppComponent, {
      disableClose: true,
      height: "722px",
      maxHeight: "100vh",
    });
    dialogref.afterClosed().subscribe((result) => {
      this.getReleaseNotes();
    });
  }
  public showSyncappPopup() {
    const dialogref = this.dialog.open(AlertComponent, {
      data: {
        title: "Sync Apps?",
        message: "Do you want to sync app?",
        description: "Doing this will update latest apps in all installed devices. This action cannot be undone.",
      },
    });
    dialogref.afterClosed().subscribe((result) => {
      if (result === true) {
        this.syncapps();
      }
    });
  }
  public deleteApp(id: number) {
    const dialogref = this.dialog.open(AlertComponent, {
      data: {
        title: "Delete App?",
        message: "Do you want to Delete app?",
        description: "Doing this will update latest apps in all installed devices.",
      },
    });
    dialogref.afterClosed().subscribe((result) => {
      if (result === true) {
        this.updateStatus(1, id);
      }
    });
  }
  public restoreApp(id: number) {
    const dialogref = this.dialog.open(AlertComponent, {
      data: {
        title: "Restore App?",
        message: "Do you want to Restore app?",
        description: "Doing this will update latest apps in all installed devices.",
      },
    });
    dialogref.afterClosed().subscribe((result) => {
      if (result === true) {
        this.updateStatus(0, id);
      }
    });
  }
  public mankeUnavailableApp(id: number) {
    const dialogref = this.dialog.open(AlertComponent, {
      data: {
        title: "Remove App Availability?",
        message: "Do you want to remove this app from available?",
        description: "Removing this app availability will update user's devices",
      },
    });
    dialogref.afterClosed().subscribe((result) => {
      if (result === true) {
        this.makeappavailable(1, id);
      }
    });
  }
  public makeavailable(id: number) {
    const dialogref = this.dialog.open(AlertComponent, {
      data: {
        title: "Make App Available?",
        message: "Do you want to make this app available?",
        description: "Making this app available will update user's devices.",
      },
    });
    dialogref.afterClosed().subscribe((result) => {
      if (result === true) {
        this.makeappavailable(0, id);
      }
    });
  }
  /**
   * @method syncapps
   * @description this method will syncing apps
   */
  public syncapps() {
    this.apiService.httpGet("customos/apps/update/0").subscribe((response: any) => {
      this.commonService.hideSpinner();
      if (response.error === 0) {
        this.getReleaseNotes();
        this.commonService.showSuccessToaster(response.message, "Success");
      } else {
        this.commonService.showErrorToaster(response.message, "Error");
      }
    });
  }
  public updateStatus(status: number, deleteAppId: number) {
    this.apiService.httpDelete("customos/apps/" + deleteAppId + "/" + status).subscribe((resp: any) => {
      this.commonService.hideSpinner();
      if (resp.error === 0) {
        this.getReleaseNotes();
        this.commonService.showSuccessToaster(resp.message, "Success");
      } else {
        this.commonService.showErrorToaster(resp.message, "Error");
      }
    });
  }

  // this path will change according to
  public makeappavailable(status: number, availAppId: number) {
    this.apiService.httpPost("customos/app/available/" + availAppId + "/" + status, {}).subscribe((resp: any) => {
      if (resp.error === 0) {
        this.getReleaseNotes();
        this.commonService.showSuccessToaster(resp.message, "Success");
      } else {
        this.commonService.showErrorToaster(resp.message, "Error");
      }
      this.commonService.hideSpinner();
    });
  }
  /**
   * @method getReleaseNotes
   * @description this method fetches the previous release notes
   */
  private getReleaseNotes() {
    this.apiService.httpGet("customos/apps").subscribe((response: any) => {
      if (response && response.error == 0) {
        this.releaseNotes = response.data;
      } else {
        this.commonService.showErrorToaster(response.message, "Error");
      }
      this.commonService.hideSpinner();
    });
  }
}
