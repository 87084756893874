import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { CommonService } from "../services/common.service";

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Router, RouterStateSnapshot } from "@angular/router";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  public headers: HttpHeaders = new HttpHeaders();

  constructor(public commonService: CommonService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.commonService.errorMessage = "";
    if (req.url.indexOf("AWSAccessKeyId") == -1) {
      this.setHeaders();
    } else if (req.url.indexOf("AWSAccessKeyId") >= 0 && req.method == "PUT") {
      console.log("sfsfs");
      this.headers = new HttpHeaders();
      this.headers = this.headers.append("Content-Type", "application/vnd.android.package-archive");
    }
    this.commonService.showSpinner();
    req = req.clone({ headers: this.headers });
    return next
      .handle(req)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event !== null && event.body.error && event.body.error !== 0 && event.body.error !== 4) {
              if (event.body.error === 2) {
                this.logout();
                this.commonService.hideSpinner();
                return false;
              } else {
                this.actionOnError(event.body.error, event.body.message);
                return event;
              }
            }
            if (this.router.url !== "/app/account") {
              this.commonService.hideSpinner();
            }
          }
          return event;
        })
      )
      .pipe(
        catchError((error) => {
          console.log(error);
          this.commonService.hideSpinner();
          if (error && error.body) {
            this.commonService.showErrorToaster(error.body.message, "");
          }
          return of(error);
        }) as any
      );
  }

  /**
   * @method setHeaders
   * @description this method is used to set the headers
   */
  private setHeaders() {
    const access = localStorage.getItem("access-token");
    this.headers = new HttpHeaders();
    // this.headers = this.headers.append('Accept', 'application/json');

    if (access && access !== "") {
      this.headers = this.headers.append("accessToken", this.commonService.decryptedToken);
    }
  }

  /**
   * @method actionOnError
   * @description this method used to make necessary action based on error code
   */
  private actionOnError(error: number, message: string) {
    this.commonService.errorMessage = message;
  }

  private logout() {
    localStorage.clear();
    this.router.navigate(["/login"]);
    this.commonService.showErrorToaster("session expired", "Logout");
  }
}
