import { Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { ApiService } from "src/services/api.service";
import { CommonService } from "src/services/common.service";

@Component({
  selector: "app-create-track",
  templateUrl: "./create-track.component.html",
  styleUrls: ["./create-track.component.scss"],
})
export class CreateTrackComponent implements OnInit {
  public form!: FormGroup;
  constructor(
    public dialogref: MatDialogRef<CreateTrackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private apiService: ApiService,
    public commonService: CommonService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      name: ["", Validators.required],
    });
    console.log("DATA ::: ", this.data);

    if (this.data) {
      this.form.patchValue({ name: this.data.name });
    }
  }

  onSubmit() {
    let data = {
      name: this.f["name"].value,
    };
    if (this.form.invalid) {
      return;
    }
    if (this.data) {
      this.apiService.httpPut("releasetrack/update/" + this.data.id, data).subscribe((response: any) => {
        this.commonService.hideSpinner();
        if (response.statusCode === 200) {
          this.commonService.showSuccessToaster("Track Updated successfully", "Success!");
          this.cancel();
        } else {
          this.commonService.showErrorToaster(response.message, "Opss!");
        }
      });
    } else {
      this.apiService.httpPost("releasetrack/create", data).subscribe((response: any) => {
        this.commonService.hideSpinner();
        if (response.statusCode === 200) {
          this.commonService.showSuccessToaster("Track Created successfully", "Success!");
          this.cancel();
        } else {
          this.commonService.showErrorToaster(response.message, "Opss!");
        }
      });
    }
  }
  cancel() {
    this.dialogref.close();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  onReset(): void {
    this.form.reset();
  }
}
