import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Router } from "@angular/router";
import * as S3 from "aws-sdk/clients/s3";
import { environment } from "src/environments/environment";
import { ApiService } from "src/services/api.service";
import { CommonService } from "src/services/common.service";
import * as JSZip from "jszip";

const bucket = new S3({
  accessKeyId: "AKIA6KEDRNVDPVFTHCXS",
  secretAccessKey: "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",
  region: "us-east-1",
});
@Component({
  selector: "app-add-os-versions",
  templateUrl: "./add-os-versions.component.html",
  styleUrls: ["./add-os-versions.component.scss"],
})
export class AddOsVersionsComponent implements OnInit {
  public form: FormGroup;
  public errorMessage = null;
  public successMessage = null;
  public progressValue = 0;
  public uploadedFiles: any;
  public apkBucket = "";
  public stageId: number;
  public metaDataTime: number;
  public progressPercentage = 0;
  public isDisable = false;
  public cancelUpload = false;
  private previousValue = 0;

  constructor(
    public dialogref: MatDialogRef<AddOsVersionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private fb: FormBuilder,
    private apiService: ApiService,
    private commonService: CommonService
  ) {
    this.InitializeForm();
  }

  ngOnInit() {}

  /**
   * @method InitializeForm
   * @description this will initialize form.
   */
  public InitializeForm() {
    this.form = this.fb.group({
      id: ["", { validators: [Validators.required] }],
      version: ["", { validators: [Validators.required, Validators.pattern(/^(\d+\.)?(\d+\.)?(\*|\d+)$/)] }],
      wiseOsVersion: ["", { validators: [Validators.required, Validators.pattern(/^(\d+\.)?(\d+\.)?(\*|\d+)$/)] }],
      file: ["", { validators: Validators.required }],
      romtype: ["", { validators: [Validators.required, Validators.pattern(/^[a-zA-Z]+$/)] }],
      devicetype: ["", { validators: [Validators.required] }],
      updateCategory: [""],
    });
    this.stageId = environment.stageId;
    if (this.stageId == 1) {
      this.apkBucket = "techlessdevsdk";
    } else if (this.stageId == 2) {
      this.apkBucket = "techlessstagesdk";
    } else if (this.stageId == 3) {
      this.apkBucket = "techlessprodsdk";
    }
  }
  /**
   * @method formControl
   * @description this get form controls.
   */
  get formControl() {
    return this.form.controls;
  }
  /**
   * @method onSubmit
   * @description this method will call on upload button in dialgue.
   */
  public async onSubmit() {
    if (this.form.invalid) {
      return;
    }
    const file = this.uploadedFiles.item(0);
    const currentDate: number = new Date().getTime();
    const modifiedFilename = currentDate + "_" + file.name;
    const filename = this.formControl.devicetype.value + "_" + modifiedFilename;
    const contentType = file.type;
    this.isDisable = true;
    const params = {
      Bucket: this.apkBucket,
      Key: filename,
      Body: file,
      ContentType: contentType,
    };
    const body = {
      datetime: this.metaDataTime,
      filename: modifiedFilename,
      id: this.formControl.id.value,
      romtype: this.formControl.romtype.value,
      size: file.size,
      version: this.formControl.version.value,
      wiseOsVersion: this.formControl.wiseOsVersion.value,
      devicetype: this.formControl.devicetype.value,
      updateCategory: this.formControl.updateCategory.value ? "1" : "0",
    };
    console.log("body === ", body);

    await bucket
      .upload(params, (err, data) => {
        if (err) {
          this.commonService.showErrorToaster("Request Time out", "Error");
          this.dialogref.close();
          console.log("Data :", data);
          console.log("Error :", JSON.stringify(err));
          return false;
        } else {
          if (!this.cancelUpload) {
            this.apiService.httpPost("customossdk", body).subscribe((respoonse: any) => {
              this.commonService.hideSpinner();
              if (respoonse && respoonse.statusCode == 200) {
                this.commonService.showSuccessToaster("OS added successfully", "success");
              } else {
                this.commonService.showErrorToaster(respoonse.message, "Error");
              }
            });
          }
        }
      })
      .on("httpUploadProgress", (progress) => {
        if (!this.cancelUpload) {
          this.progressValue = Math.round((progress.loaded / progress.total) * 100);
          if (this.progressValue === 100) {
            this.isDisable = false;
          }
          // console.log(HttpResponse);
          if (this.previousValue > this.progressValue) {
            this.commonService.showErrorToaster("Poor internet Connection", "Reconnecting");
          }
          this.previousValue = this.progressValue;
        }
      });
    // .send(function (err, data) {

    // });
  }
  /**
   * @method clearForm
   * @description this method will clear inputs.
   */
  public clearForm() {
    this.cancelUpload = true;
    this.form.reset();
  }

  /**
   * @method fileChange
   * @param element
   * @description this method will check file extenion .zip type.
   */
  public fileChange(element: any) {
    try {
      this.isDisable = false;
      this.cancelUpload = false;
      this.progressValue = 0;
      const ext = /^.+\.([^.]+)$/.exec(element.target.files.item(0).name);
      const extension = ext == null ? "" : ext[1];

      if (extension === "zip") {
        this.errorMessage = "";
        this.uploadedFiles = element.target.files;
        const file = this.uploadedFiles.item(0);
        JSZip.loadAsync(file).then((zip) => {
          if (Object.keys(zip.files).length > 0 && Object.keys(zip.files)[0] === "META-INF/com/android/metadata") {
            zip.files["META-INF/com/android/metadata"].async("string").then((fileData) => {
              let stringValue = fileData.toString();
              try {
                let replacesString = stringValue.split("post-timestamp=")[1];
                this.metaDataTime = parseInt(replacesString.split("npre-device")[0]);
                console.log(this.metaDataTime);
              } catch (error) {
                this.errorMessage = "Invalid MetaData Format";
                this.form.patchValue({
                  file: "",
                });
                console.log("errror  === ", error);
              }
            });
          } else {
            this.errorMessage = "Invalid OS Build";
            this.form.patchValue({
              file: "",
            });
          }
        });
      } else {
        this.errorMessage = "Please select only .zip file";
        this.form.patchValue({
          file: "",
        });
      }
    } catch (error) {
      console.log("error");
    }
  } //
}
